import { Avatar, Stack, Typography } from "@mui/material";
import InfoIcon from "../../assets/img/InformationOrangeIcon.png";
import { theme } from "../../theme/Theme";
import { useState } from "react";
import EftPaymentInfoPopup from "./EftPaymentInfoPopup";
import { useFeatureFlag } from "configcat-react";
import { CONFIGCAT_KEYS } from "../../configCat/configCatKeys";

const EftDeprecationBanner = () => {
    const { value: showEftDeprecationBanner, loading: showEftDeprecationBannerLoading } = useFeatureFlag(CONFIGCAT_KEYS.SHOW_REPAY_EFT_DEPRECATION_BANNER.key, CONFIGCAT_KEYS.SHOW_REPAY_EFT_DEPRECATION_BANNER.defaultValue);
    const [showPaymentInfoPopup, setShowPaymentInfoPopup] = useState(false);

    return (
        <>
            {showEftDeprecationBanner && !showEftDeprecationBannerLoading &&
                <Stack
                    position={'relative'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={'0.8rem'}
                    paddingX={'1.2rem'}
                    paddingY={{ xs: '1.2rem', md: '1.7rem' }}
                    marginBottom={{ xs: '2rem', md: '2.4rem' }}
                    sx={{
                        backgroundColor: '#FDE3CB',
                        borderRadius: '1rem'
                    }}>
                    <Avatar src={InfoIcon} sx={{ width: '17px', height: '17px', transform: 'rotate(180deg)' }} />
                    <div>
                        <Typography fontSize={'1.5rem'} fontWeight={'300'} lineHeight={{ xs: '2rem', md: '1.7rem' }}>
                            Please note that we no longer support regular EFT payments.{' '}
                            <Typography
                                component={'span'}
                                fontSize={'1.5rem'}
                                fontWeight={'300'}
                                lineHeight={{ xs: '2rem', md: '1.7rem' }}
                                color={theme.palette.primary.main}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => setShowPaymentInfoPopup(true)}>
                                Find out more
                            </Typography>
                        </Typography>
                    </div>
                    <EftPaymentInfoPopup open={showPaymentInfoPopup} handleClose={() => setShowPaymentInfoPopup(false)} />
                </Stack>
            }
        </>
    );
};

export default EftDeprecationBanner;