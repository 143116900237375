/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack, Avatar, Typography, useMediaQuery, Box } from "@mui/material";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import { theme } from "../../../theme/Theme";
import InformationOrangeIcon from "../../../assets/img/InformationOrangeIcon.png";
import SavingsIcon from "../../../assets/img/PiggyBank.png";
import { IGetLoansSummaryResponse } from "../../../clients/LoanClient";
import React, { useEffect } from "react";
import { getFriendlyOpenLoanStatus, OpenLoanStatusEnum } from "../../../utils/Helpers/OpenLoanStatusEnum";
import { PersonalDetails } from "../../../clients/AccountClient";
import { concurrentLoanJourney } from "../../Wizard/JourneyConfig/JourneyConfigs";
import { useTracking } from "../../../Tracking/TrackingContext";
import { RouterRoutes } from "../../../utils/RouterRoutes";
import useCustomNavigate from "../../../hooks/Navigation";

type OpenLoanSummaryProps = {
    loansSummaryResponse: IGetLoansSummaryResponse;
    personalDetails: PersonalDetails | undefined;
}

const OpenLoanSummary: React.FunctionComponent<OpenLoanSummaryProps> = (props: OpenLoanSummaryProps) => {
    const content = useLoanSummaryContent(props.loansSummaryResponse, props.personalDetails);

    if (!content)
        return <></>;

    return (
        <Stack
            flexDirection={"column"}
            alignItems={'center'}
            justifyContent={'center'}
            padding={{ xs: '1.2rem 1.6rem' }}
            sx={{ backgroundColor: theme.palette.grey[200], borderRadius: '1rem' }}
            gap={'1.2rem'}
            marginBottom={{ xs: '2.4rem', md: '2.8rem' }}
        >
            {content}
        </Stack>
    );
}

export default OpenLoanSummary;

const Title = (props: { text: 'Open application' | 'Overdue' }) => {
    return <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={'1rem'}
    >
        <Avatar src={InformationOrangeIcon} alt="icon" sx={{ width: { xs: '1.6rem', md: '2.2rem' }, height: { xs: '1.6rem', md: '2.2rem' } }} />
        <Typography
            variant="h2"
            fontSize={'1.8rem'}
            fontWeight={600}
            lineHeight={'2rem'}
            color={theme.palette.secondary.main}
        >
            {props.text}
        </Typography>
    </Stack>;
}

const useLoanSummaryContent = (response: IGetLoansSummaryResponse, personalDetails: PersonalDetails | undefined) => {
    const { hasOpenLoanOrApplication, openApplication, loans, canApply } = response;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const hasLoanInArrears = loans?.find(l => getFriendlyOpenLoanStatus(l.openLoanStatus) == OpenLoanStatusEnum.InArrears);
    const hasActiveLoan = loans?.find(l => getFriendlyOpenLoanStatus(l.openLoanStatus) == OpenLoanStatusEnum.Active);
    const { SetIsConcurrentLoanEligible } = useTracking();
    const { navigateWithGclidParams } = useCustomNavigate();

    useEffect(() => {
        const isConcurrentLoanEligible = (hasActiveLoan && canApply) ?? false;
        SetIsConcurrentLoanEligible(isConcurrentLoanEligible);
    }, [SetIsConcurrentLoanEligible, canApply, hasActiveLoan]);

    const handleNavigateOffer = () => {
        navigateWithGclidParams(`/${RouterRoutes.concurrentJourney}/${concurrentLoanJourney[0].path}`, { state: { loanAmount: response.maxLoanAmount, term: 3, salaryDay: personalDetails?.employment?.salaryDay || 25 } })
    }

    if (hasOpenLoanOrApplication) {
        if (openApplication !== null && openApplication !== undefined) {
            const openLoanStatus = getFriendlyOpenLoanStatus(openApplication.openLoanStatus);

            if (openLoanStatus === OpenLoanStatusEnum.Unspecified) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            You have an open loan application. Please click here to continue.
                        </Typography>
                        <PrimaryButton onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)}>View application outcome</PrimaryButton>
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.PendingApplicationSigning) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            You have an open loan application. Please click here to continue before it expires.
                        </Typography>
                        <PrimaryButton onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)}>Sign loan agreement</PrimaryButton>
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.PendingIvDocUpload) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            You have an open loan application. Please click here to continue before it expires.
                        </Typography>
                        <PrimaryButton onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)}>Submit proof of income</PrimaryButton>
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.PendingIncomeAndBankVerification) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            We are currently reviewing your income & bank account details.{' '}
                            {!isMobile && (
                                <Typography
                                    onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)}
                                    component={'span'}
                                    fontSize={'1.6rem'}
                                    fontWeight={400}
                                    lineHeight={'2rem'}
                                    color={theme.palette.primary.main}
                                    sx={{ cursor: 'pointer' }}
                                >View loan</Typography>
                            )}
                        </Typography>
                        {isMobile && (
                            <Box width={'100%'} onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)} sx={{ cursor: 'pointer' }}>
                                <Typography
                                    fontSize={'1.6rem'}
                                    fontWeight={400}
                                    lineHeight={'2rem'}
                                    color={theme.palette.primary.main}>
                                    View loan
                                </Typography>
                            </Box>
                        )}
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.PendingMandateAcceptance) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            You have an open loan application. Please accept your DebiCheck mandate before your application expires.{' '}
                            {!isMobile && (
                                <Typography
                                    onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)}
                                    component={'span'}
                                    fontSize={'1.6rem'}
                                    fontWeight={400}
                                    lineHeight={'2rem'}
                                    marginRight={'1rem'}
                                    color={theme.palette.primary.main}
                                    sx={{ cursor: 'pointer' }}
                                >View loan</Typography>
                            )}
                        </Typography>
                        {isMobile && (
                            <Box width={'100%'} onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)} sx={{ cursor: 'pointer' }}>
                                <Typography
                                    fontSize={'1.6rem'}
                                    fontWeight={400}
                                    lineHeight={'2rem'}
                                    color={theme.palette.primary.main}
                                >View loan</Typography>
                            </Box>
                        )}
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.Disbursing) {
                return (
                    <>
                        <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            gap={'1rem'}
                        >
                            <Avatar src={SavingsIcon} alt="icon" sx={{ width: '2.6rem', height: '2.5rem', borderRadius: 'none' }} />
                            <Typography
                                fontSize={'1.5rem'}
                                fontWeight={300}
                                lineHeight={'2rem'}
                            >
                                Your recent loan application has been <strong>approved</strong> & the cash is on its way!{' '}
                                {!isMobile && (
                                    <Typography
                                        onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)}
                                        width={'100%'}
                                        component={'span'}
                                        fontSize={'1.6rem'}
                                        fontWeight={400}
                                        lineHeight={'2rem'}
                                        marginRight={'1rem'}
                                        color={theme.palette.primary.main}
                                        sx={{ cursor: 'pointer' }}
                                    >View loan</Typography>
                                )}
                            </Typography>
                        </Stack>
                        {isMobile && (
                            <Box width={'100%'} onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)} sx={{ cursor: 'pointer', paddingLeft: '3.6rem' }}>
                                <Typography
                                    fontSize={'1.6rem'}
                                    fontWeight={400}
                                    lineHeight={'2rem'}
                                    color={theme.palette.primary.main}
                                >View loan</Typography>
                            </Box>
                        )}
                    </>
                );
            }

            if (openLoanStatus === OpenLoanStatusEnum.CounterofferAvailable) {
                return (
                    <>
                        <Title text={"Open application"} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            We have reviewed your loan application & have an adjusted offer. Please click here to continue before it expires.
                        </Typography>
                        <PrimaryButton onClick={() => navigateWithGclidParams(`/${RouterRoutes.myLoan}/${openApplication.loanId}`)}>View loan offer</PrimaryButton>
                    </>
                );
            }
        }

        if (hasLoanInArrears) {
            return (
                <>
                    <Title text={"Overdue"} />
                    <Typography
                        fontSize={'1.5rem'}
                        fontWeight={300}
                        lineHeight={'2rem'}
                    >
                        One or more of your loans is currently{' '}
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={400}
                            lineHeight={'2rem'}
                            component={'span'}
                            color={theme.palette.secondary.main}
                        >overdue.</Typography>{' '}
                        Please settle this amount to avoid interest accruing on your outstanding amount. If paid already, please ignore.
                    </Typography>
                </>
            );
        }

        if (hasActiveLoan && canApply) {
            return (
                <>
                    <Stack
                        flexDirection={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={'1rem'}
                    >
                        <Avatar src={SavingsIcon} alt="icon" sx={{ width: '2.6rem', height: '2.5rem', borderRadius: 'none' }} />
                        <Typography
                            fontSize={'1.5rem'}
                            fontWeight={300}
                            lineHeight={'2rem'}
                        >
                            Need more cash? Take an <strong>additional loan</strong> of up to <strong>R {response.maxLoanAmount}.</strong>
                        </Typography>
                    </Stack>
                    <Box sx={{ width: '327px' }}>
                        <PrimaryButton onClick={handleNavigateOffer} fullWidth height="32px">Apply now</PrimaryButton>
                    </Box>
                </>
            )
        }
    }
}