import { useNavigate as useReactRouterNavigate, NavigateOptions, To, useSearchParams } from "react-router-dom";
import { navigateToExternalPage } from "../utils/Helpers/NavigationHelper";
import { useAppSettings } from "../contexts/AppSettingsContext";

const utmSourceKey = "utm-source";
const utmMediumKey = "utm-medium";
const gclidKey = "gclid";

const useCustomNavigate = () => {
    const navigate = useReactRouterNavigate();
    const [searchParams] = useSearchParams();
    const utmSource = searchParams.get("utm_source") ?? localStorage.getItem(utmSourceKey);
    const utmMedium = searchParams.get("utm_medium") ?? localStorage.getItem(utmMediumKey);
    const gclid = searchParams.get("gclid") ?? localStorage.getItem(gclidKey);
    const { googleTagManager } = useAppSettings();
    const enhancedTrackingEnabled = googleTagManager.enhancedTrackingEnabled;

    const navigateWithGclidParams = (to: To, options?: NavigateOptions) => {
        let destination: To = to;

        if (utmSource && utmMedium && gclid && typeof to === "string" && enhancedTrackingEnabled) {
            const url = new URL(to, window.location.origin);
            url.searchParams.append("utm_source", utmSource);
            url.searchParams.append("utm_medium", utmMedium);
            url.searchParams.append("gclid", gclid);
            localStorage.setItem(utmSourceKey, utmSource);
            localStorage.setItem(utmMediumKey, utmMedium);
            localStorage.setItem(gclidKey, gclid);
            destination = url.pathname + url.search;
        }

        navigate(destination, options);
    };

    const navigateExternalWithGclidParams = (to: string) => {
        let destination: string = to;

        if (utmSource && utmMedium && gclid && enhancedTrackingEnabled) {
            const url = new URL(to, window.location.origin);
            url.searchParams.append("utm_source", utmSource);
            url.searchParams.append("utm_medium", utmMedium);
            url.searchParams.append("gclid", gclid);
            localStorage.setItem(utmSourceKey, utmSource);
            localStorage.setItem(utmMediumKey, utmMedium);
            localStorage.setItem(gclidKey, gclid);
            destination = url.pathname + url.search;
        }

        navigateToExternalPage(destination);
    };

    const appendPageKeyWithGclidParams = (to: string) => {
        let destination: string = to;

        if (utmSource && utmMedium && gclid && enhancedTrackingEnabled) {
            const url = new URL(to, window.location.origin);
            url.searchParams.append("utm_source", utmSource);
            url.searchParams.append("utm_medium", utmMedium);
            url.searchParams.append("gclid", gclid);
            localStorage.setItem(utmSourceKey, utmSource);
            localStorage.setItem(utmMediumKey, utmMedium);
            localStorage.setItem(gclidKey, gclid);
            destination = url.pathname + url.search;
        }
        //using the window object to append the page key to the url because we don't want react to re-render the same component
        window.history.replaceState(null, '', destination)
    };

    return { navigateWithGclidParams, navigateExternalWithGclidParams, appendPageKeyWithGclidParams }
};

export default useCustomNavigate;