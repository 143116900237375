import { NavigateFunction, NavigateOptions, To } from "react-router-dom";
import { PostProcessingRoutes, RouterRoutes } from "../RouterRoutes";

export const enum JourneyType {
    l0Journey = 'l0Journey',
    lnJourney = 'lnJourney',
    lnJourneyShortened = 'lnJourneyShortened',
    concurrentLoan = 'concurrent'
}

export const navigateToTermsAndConditions = (navigate: NavigateFunction | ((to: To, options?: NavigateOptions) => void), loanId: string, isExistingCustomer: boolean, isConcurrentLoan: boolean, replaceRoute?: boolean) => {
    const navigateOptions = { state: { loanId }, replace: replaceRoute };
    let journeyRoute = RouterRoutes.newJourney;

    if (isExistingCustomer && !isConcurrentLoan)
        journeyRoute = RouterRoutes.existingJourney
    else if (isExistingCustomer && isConcurrentLoan)
        journeyRoute = RouterRoutes.concurrentJourney

    navigate(`/${journeyRoute}/${PostProcessingRoutes.agreement}`, navigateOptions);
}

export const navigateToDocumentUpload = (navigate: NavigateFunction | ((to: To, options?: NavigateOptions) => void), loanId: string, isExistingCustomer: boolean, isConcurrentLoan: boolean, replaceRoute?: boolean) => {
    const navigateOptions = { state: { loanId }, replace: replaceRoute };
    let journeyRoute = RouterRoutes.newJourney;

    if (isExistingCustomer && !isConcurrentLoan)
        journeyRoute = RouterRoutes.existingJourney
    else if (isExistingCustomer && isConcurrentLoan)
        journeyRoute = RouterRoutes.concurrentJourney

    navigate(`/${journeyRoute}/${PostProcessingRoutes.documentUpload}`, navigateOptions);
}

export const navigateToExternalPage = (url: string) => {
    window.location.assign(url);
}

export const navigateToCounterOfferProcessing = (navigate: NavigateFunction | ((to: To, options?: NavigateOptions) => void), loanId: string, isExistingCustomer: boolean, isConcurrentLoan: boolean, replaceRoute?: boolean) => {
    const navigateOptions = { state: { loanId }, replace: replaceRoute };
    let journeyRoute = RouterRoutes.newJourney;

    if (isExistingCustomer && !isConcurrentLoan)
        journeyRoute = RouterRoutes.existingJourney
    else if (isExistingCustomer && isConcurrentLoan)
        journeyRoute = RouterRoutes.concurrentJourney

    navigate(`/${journeyRoute}/${PostProcessingRoutes.counterOfferProcessing}`, navigateOptions);
}

export const navigateToCounterOfferDecline = (navigate: NavigateFunction | ((to: To, options?: NavigateOptions) => void), loanId: string, isExistingCustomer: boolean, isConcurrentLoan: boolean, replaceRoute?: boolean) => {
    const navigateOptions = { state: { loanId }, replace: replaceRoute };
    let journeyRoute = RouterRoutes.newJourney;

    if (isExistingCustomer && !isConcurrentLoan)
        journeyRoute = RouterRoutes.existingJourney
    else if (isExistingCustomer && isConcurrentLoan)
        journeyRoute = RouterRoutes.concurrentJourney

    navigate(`/${journeyRoute}/${PostProcessingRoutes.counterOfferDecline}`, navigateOptions);
}

export const navigateToIvOptionsPage = (navigate: NavigateFunction | ((to: To, options?: NavigateOptions) => void), loanId: string, isExistingCustomer: boolean, isConcurrentLoan: boolean, errorDialogOpened: boolean) => {
    const navigateOptions = { state: { loanId, errorDialogOpened }, replace: true };
    let journeyRoute = RouterRoutes.newJourney;

    if (isExistingCustomer && !isConcurrentLoan)
        journeyRoute = RouterRoutes.existingJourney
    else if (isExistingCustomer && isConcurrentLoan)
        journeyRoute = RouterRoutes.concurrentJourney

    navigate(`/${journeyRoute}/${PostProcessingRoutes.IvOptions}`, navigateOptions);
}

export const navigateToOpenBankingProcessing = (navigate: NavigateFunction | ((to: To, options?: NavigateOptions) => void), loanId: string, isExistingCustomer: boolean, isConcurrentLoan: boolean) => {
    const navigateOptions = { state: { loanId }, replace: true };
    let journeyRoute = RouterRoutes.newJourney;

    if (isExistingCustomer && !isConcurrentLoan)
        journeyRoute = RouterRoutes.existingJourney
    else if (isExistingCustomer && isConcurrentLoan)
        journeyRoute = RouterRoutes.concurrentJourney

    navigate(`/${journeyRoute}/${PostProcessingRoutes.OpenBankingProcessing}`, navigateOptions);
}

export const navigateToFailedOpenBanking = (navigate: NavigateFunction | ((to: To, options?: NavigateOptions) => void), loanId: string, isExistingCustomer: boolean, loanAmount: number, title: string, isConcurrentLoan: boolean,) => {
    const navigateOptions = { state: { loanId, loanAmount, title }, replace: true };
    let journeyRoute = RouterRoutes.newJourney;

    if (isExistingCustomer && !isConcurrentLoan)
        journeyRoute = RouterRoutes.existingJourney
    else if (isExistingCustomer && isConcurrentLoan)
        journeyRoute = RouterRoutes.concurrentJourney

    navigate(`/${journeyRoute}/${PostProcessingRoutes.OpenBankingFailure}`, navigateOptions);
}

export const navigateToCancelledOpenBanking = (navigate: NavigateFunction | ((to: To, options?: NavigateOptions) => void), loanId: string, isExistingCustomer: boolean,  isConcurrentLoan: boolean) => {
    const navigateOptions = { state: { loanId }, replace: true };
    let journeyRoute = RouterRoutes.newJourney;

    if (isExistingCustomer && !isConcurrentLoan)
        journeyRoute = RouterRoutes.existingJourney
    else if (isExistingCustomer && isConcurrentLoan)
        journeyRoute = RouterRoutes.concurrentJourney

    navigate(`/${journeyRoute}/${PostProcessingRoutes.OpenBankingCancelled}`, navigateOptions);
}

export const determineJourneyType = (isExistingCustomer: boolean, hasAllPersonalDetails: boolean, isConcurrentLoan: boolean): JourneyType => {
    if (!isConcurrentLoan && isExistingCustomer && hasAllPersonalDetails) return JourneyType.lnJourneyShortened;
    else if (isConcurrentLoan && isExistingCustomer && hasAllPersonalDetails) return JourneyType.concurrentLoan;
    else if (!isConcurrentLoan && isExistingCustomer && !hasAllPersonalDetails) return JourneyType.lnJourney;
    return JourneyType.l0Journey;
}

export const determineJourneyPath = (isExistingCustomer: boolean, isConcurrentLoan: boolean): string => {
    if (!isConcurrentLoan && isExistingCustomer) return RouterRoutes.existingJourney;
    else if (isConcurrentLoan && isExistingCustomer) return RouterRoutes.concurrentJourney;
    return RouterRoutes.newJourney;
}
