import { z } from 'zod';
import { ValidateIdNumber } from '../components/Validation/IdNumberValidator';
import {
  emailContraints,
  incomeConstraints,
  mobileNumberConstraints,
  newEmailContraints,
  otpContraints,
  passwordConstraints,
} from './CommonContraints';
import {
  BankAccountType,
  EmploymentIndustryEnum,
  EmploymentStatusEnum,
  HomeLanguageEnum,
  HomeStatusEnum,
  IncomeFrequencyEnum,
  MaritalStatusEnum,
  PositionEnum,
} from '../clients/AccountClient';
import RegularExpressions from '../components/Validation/RegularExpressions';
import { BeneficiaryRelationshipEnum } from '../models/enums/BeneficiaryRelationshipEnum';

export const signInFormSchema = z.object({
  email: z.string().email('Please enter a valid email address.'),
  password: z.string().nonempty({ message: 'This field is required' }),
});

export const loginFormSchema = z
  .object({
    emailOrIdNumber: z.string(),
    password: z.string().nonempty({ message: 'This field is required' }),
  })
  .superRefine((data, ctx) => {
    const emailOrIdNumber = data.emailOrIdNumber;
    const emailPassed = new RegExp(RegularExpressions.email).test(emailOrIdNumber);
    const isIdNumber = new RegExp(RegularExpressions.numericOnly).test(emailOrIdNumber);
    const idNumberError = ValidateIdNumber(emailOrIdNumber);

    if (isIdNumber && idNumberError) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `${idNumberError}`,
        path: ['emailOrIdNumber'],
      });
    }

    if (!isIdNumber && !emailPassed) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please enter a valid email address.',
        path: ['emailOrIdNumber'],
      });
    }

    return true;
  });

export const registerFormSchema = (lastEmailExists: string) =>
  z
    .object({
      firstname: z
        .string()
        .trim()
        .min(2, { message: 'Please enter a valid first name' })
        .max(75, { message: 'Please enter a valid first name' })
        .regex(new RegExp(RegularExpressions.noSpecialChars), {
          message: 'You have entered illegal characters in this field',
        })
        .regex(new RegExp(RegularExpressions.noNumeric), {
          message: 'No numbers allowed',
        }),
      lastname: z
        .string()
        .trim()
        .min(2, { message: 'Please enter a valid last name' })
        .max(75, { message: 'Please enter a valid last name' })
        .regex(new RegExp(RegularExpressions.noSpecialChars), {
          message: 'You have entered illegal characters in this field',
        })
        .regex(new RegExp(RegularExpressions.noNumeric), {
          message: 'No numbers allowed',
        }),
      idNumber: z
        .string()
        .min(13, { message: 'This is not a valid RSA ID' })
        .max(13, { message: 'This is not a valid RSA ID' })
        .superRefine((id, ctx) => {
          const errorMessage = ValidateIdNumber(id);
          if (errorMessage !== undefined) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: `${errorMessage}`,
            });
          }
        }),
      email: newEmailContraints,
      password: passwordConstraints,
      privacyPolicyAccepted: z.boolean({
        required_error: 'Please accept the privacy policy',
      }),
    })
    .refine((data) => lastEmailExists !== data.email, {
      message: 'This email is already in use, please log in',
      path: ['email'],
    });

export const passwordSchema = z
  .object({
    password: passwordConstraints,
    newPassword: passwordConstraints,
    confirmNewPassword: passwordConstraints,
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    message: "Passwords don't match",
    path: ['confirmNewPassword'], // path of error
  })
  .refine((data) => data.password !== data.newPassword, {
    message: 'New password cannot equal to the current password',
    path: ['newPassword'], // path of error
  });

export const addressSchema = z.object({
  houseNumber: z
    .string()
    .trim()
    .min(1, { message: 'Not a valid house number' })
    .max(50, { message: 'Consider using a short house number' })
    .regex(new RegExp(RegularExpressions.alphaNumeric), {
      message: 'Field must contains alphanumeric values',
    }),
  streetName: z
    .string()
    .trim()
    .min(2, { message: 'Not a valid Street name' })
    .max(50, { message: 'Consider using a short Street name' })
    .regex(new RegExp(RegularExpressions.noSpecialChars), {
      message: 'Field must contains no special characters',
    }),

  suburb: z
    .string()
    .trim()
    .min(2, { message: 'Not a valid Suburb' })
    .max(50, { message: 'Consider using a short Suburb name' })
    .regex(new RegExp(RegularExpressions.textOnly), {
      message: 'Field must contains only letters',
    }),
  city: z
    .string()
    .trim()
    .min(2, { message: 'Not a valid City' })
    .max(50, { message: 'Consider using a short City name' })
    .regex(new RegExp(RegularExpressions.textOnly), {
      message: 'Field must contains only letters',
    }),
  province: z
    .string()
    .min(2, { message: 'Not a valid Province' })
    .max(50, { message: 'Consider using a short Province name' }),
  postalCode: z
    .string()
    .trim()
    .min(4, { message: 'This is not a valid postcode' })
    .max(4, { message: 'This is not a valid postcode' })
    .regex(new RegExp(RegularExpressions.numericOnly), {
      message: 'Field must contains only numbers',
    }),
});

export const cellphoneSchema = z.object({
  cellphoneNumber: mobileNumberConstraints,
});

export const OTPSchema = z.object({
  cellphoneNumber: mobileNumberConstraints,
  otp: otpContraints,
});

export const emailSchema = z.object({
  email: emailContraints,
});

export const changeEmailSchema = z.object({
  email: newEmailContraints,
});

export const nameSchema = z.object({
  name: z.string(),
});

export const marketingPreferenceSchema = z.object({
  marketing: z.boolean(),
});

export const customerDetailsSchema = z.object({
  dependents: z.string().nonempty({ message: 'This field is required' }),
  homeStatus: z.nativeEnum(HomeStatusEnum).refine((value) => value !== HomeStatusEnum.Unspecified, {
    message: 'This field is required',
  }),
  homeLanguage: z.nativeEnum(HomeLanguageEnum).refine((value) => value !== HomeLanguageEnum.Unspecified, {
    message: 'This field is required',
  }),
  maritalStatus: z.nativeEnum(MaritalStatusEnum).refine((value) => value !== MaritalStatusEnum.Unspecified, {
    message: 'This field is required',
  }),
  marketing: z.boolean(),
});

export const employmentDetailsSchema = z
  .object({
    employerName: z.string().trim().max(100, { message: 'Employer name must be less than 100 characters' }).optional(),
    position: z.nativeEnum(PositionEnum),
    industry: z.nativeEnum(EmploymentIndustryEnum),
    netIncome: incomeConstraints,
    incomeFrequency: z.nativeEnum(IncomeFrequencyEnum),
    salaryDay: z.string().superRefine((value, ctx) => {
      const numValue = parseInt(value, 10);
      if (isNaN(numValue)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'This field is required',
        });
      }
      if (numValue < 1 || numValue > 31) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Only numbers between 1 and 31 to be used',
        });
      }
    }),
    employmentStatus: z.nativeEnum(EmploymentStatusEnum),
    employedOn: z.date().optional(),
    grossIncome: incomeConstraints,
    workPhone: z.string().optional(),
    university: z.string().optional(),
    division: z.string().optional(),
  })
  .refine((data) => parseInt(data.netIncome) <= parseInt(data.grossIncome), {
    message: 'Nett income needs to be less than Gross Income',
    path: ['netIncome'],
  })
  .refine(
    (data) => {
      const isFullTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedFullTime;
      const isPartTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedPartTime;
      const isTemporaryEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedTemporary;
      if (isFullTimeEmployed || isPartTimeEmployed || isTemporaryEmployed) {
        return data.employerName != null && data.employerName.length > 1;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['employerName'],
    }
  )
  .refine(
    (data) => {
      const isFullTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedFullTime;
      const isPartTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedPartTime;
      const isTemporaryEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedTemporary;
      const isValidEmployerName = new RegExp(RegularExpressions.onlyStandardCharacters).test(data.employerName ?? '');
      if (isFullTimeEmployed || isPartTimeEmployed || isTemporaryEmployed) {
        return isValidEmployerName && data.employerName != null && data.employerName.length > 1;
      }
      return true;
    },
    {
      message: 'No invalid characters allowed',
      path: ['employerName'],
    }
  )
  .refine(
    (data) => {
      const isFullTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedFullTime;
      const isPartTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedPartTime;
      const isTemporaryEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedTemporary;
      if (isFullTimeEmployed || isPartTimeEmployed || isTemporaryEmployed) {
        return data.industry != EmploymentIndustryEnum.Unspecified;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['industry'],
    }
  )
  .refine(
    (data) => {
      const isFullTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedFullTime;
      const isPartTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedPartTime;
      const isTemporaryEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedTemporary;
      if (isFullTimeEmployed || isPartTimeEmployed || isTemporaryEmployed) {
        return data.position != PositionEnum.Unspecified;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['position'],
    }
  )
  .refine(
    (data) => {
      const isFullTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedFullTime;
      const isPartTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedPartTime;
      const isTemporaryEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedTemporary;
      const isStudent = data.employmentStatus === EmploymentStatusEnum.Student;
      const isArmedForces = data.employmentStatus === EmploymentStatusEnum.ArmedForces;
      if (isFullTimeEmployed || isPartTimeEmployed || isTemporaryEmployed || isStudent || isArmedForces) {
        return !!data.employedOn;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['employedOn'],
    }
  )
  .refine(
    (data) => {
      const isFullTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedFullTime;
      const isPartTimeEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedPartTime;
      const isTemporaryEmployed = data.employmentStatus === EmploymentStatusEnum.EmployedTemporary;
      const isSelfEmployed = data.employmentStatus === EmploymentStatusEnum.SelfEmployed;
      if (isFullTimeEmployed || isPartTimeEmployed || isTemporaryEmployed || isSelfEmployed) {
        const isValidNumber = new RegExp(RegularExpressions.workPhone).test(data.workPhone ?? '');
        return isValidNumber;
      }
      return true;
    },
    {
      message: 'Enter a 10 digit number starting with 0',
      path: ['workPhone'],
    }
  )
  .refine(
    (data) => {
      const isStudent = data.employmentStatus === EmploymentStatusEnum.Student;
      if (isStudent) {
        return !!data.university;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['university'],
    }
  )
  .refine(
    (data) => {
      const isArmedForces = data.employmentStatus === EmploymentStatusEnum.ArmedForces;
      if (isArmedForces) {
        return !!data.division;
      }
      return true;
    },
    {
      message: 'This field is required',
      path: ['division'],
    }
  ).superRefine((data, ctx) => {
    if (data.incomeFrequency === IncomeFrequencyEnum.Unspecified) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["incomeFrequency"],
        message: "This field is required",
      });
    }
  }).superRefine((data, ctx) => {
    if (data.employmentStatus === EmploymentStatusEnum.Unspecified) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["employmentStatus"],
        message: "This field is required",
      });
    }
  });

export const affordabilitySchema = z
  .object({
    grossIncome: z.string(),
    netIncome: z.string(),
    rentAndTaxes: z.string().regex(new RegExp(RegularExpressions.numericOnly), {
      message: 'The number must be positive',
    }),
    goods: z.string().regex(new RegExp(RegularExpressions.numericOnly), {
      message: 'The number must be positive',
    }),
    transport: z.string().regex(new RegExp(RegularExpressions.numericOnly), {
      message: 'The number must be positive',
    }),
    loanRepayments: z.string().regex(new RegExp(RegularExpressions.numericOnly), {
      message: 'The number must be positive',
    }),
    childMaintenance: z.string().regex(new RegExp(RegularExpressions.numericOnly), {
      message: 'The number must be positive',
    }),
    disposableMonthlyIncome: z.string(),
  })
  .refine((data) => parseInt(data.disposableMonthlyIncome) >= 0, {
    message: 'Calculated disposable income cannot be negative',
    path: ['disposableMonthlyIncome'], // path of error
  });

export const bankSchema = z
  .object({
    accountNumber: z.string().regex(new RegExp(RegularExpressions.numericOnly), {
      message: 'The number must be positive',
    }),
    accountType: z.nativeEnum(BankAccountType).refine((value) => value !== BankAccountType.Unspecified, {
      message: 'This field is required',
    }),
    holderName: z.string(),
    bankName: z.string().nonempty('This field is required'),
    bankCode: z.string(),
    openedOn: z.string(),
  })
  .superRefine((data, ctx) => {
    const bankName = data.bankName;
    const accountNumber = data.accountNumber;
    const savings = BankAccountType.Savings;
    const current = BankAccountType.Current;
    const cheque = BankAccountType.Cheque;
    const transmission = BankAccountType.Transmission;

    //Stops the validation on account type if it has not been selected
    if (data.accountType === BankAccountType.Unspecified) return true;

    if (bankName === 'ABSA') {
      const accountRules = [
        { type: savings, min: 1000000, max: 99999999999, minCharacterLength: 7, maxCharacterLength: 11 },
        { type: current, min: 1000000, max: 99999999999, minCharacterLength: 7, maxCharacterLength: 11 },
        { type: cheque, min: 1000000, max: 99999999999, minCharacterLength: 7, maxCharacterLength: 11 },
        { type: transmission, min: 1000000, max: 99999999999, minCharacterLength: 7, maxCharacterLength: 11 },
      ];

      const rule = accountRules.find((accRule) => accRule.type === data.accountType);

      if (!rule) {
        throw new Error('Invalid account type for ABSA bank');
      }

      const { min, max, minCharacterLength, maxCharacterLength } = rule;
      const accountNumberInt = parseInt(accountNumber);

      if (isNaN(accountNumberInt) || accountNumberInt < min || accountNumberInt > max || accountNumber.length < minCharacterLength || accountNumber.length > maxCharacterLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid Absa bank account number',
          path: ['accountNumber'],
        });
      }
    }

    if (bankName === 'Capitec') {
      const accountRules = [
        { type: savings, min: 1000000000, max: 2999999999, minCharacterLength: 10, maxCharacterLength: 10 },
        { type: current, min: 1000000000, max: 2999999999, minCharacterLength: 10, maxCharacterLength: 10 },
        { type: cheque, min: 1000000000, max: 2999999999, minCharacterLength: 10, maxCharacterLength: 10 },
        { type: transmission, min: 1000000000, max: 2999999999, minCharacterLength: 10, maxCharacterLength: 10 },
      ];

      const rule = accountRules.find((accRule) => accRule.type === data.accountType);

      if (!rule) {
        throw new Error('Invalid account type for Capitec');
      }

      const { min, max, minCharacterLength, maxCharacterLength } = rule;
      const accountNumberInt = parseInt(accountNumber);

      if (isNaN(accountNumberInt) || accountNumberInt < min || accountNumberInt > max || accountNumber.length < minCharacterLength || accountNumber.length > maxCharacterLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid Capitec bank account number',
          path: ['accountNumber'],
        });
      }
    }

    if (bankName === 'First National Bank') {
      const accountRules = [
        { type: savings, min: 50000000000, max: 69999999999, minCharacterLength: 11, maxCharacterLength: 11 },
        { type: current, min: 50000000000, max: 69999999999, minCharacterLength: 11, maxCharacterLength: 11 },
        { type: cheque, min: 50000000000, max: 69999999999, minCharacterLength: 11, maxCharacterLength: 11 },
        { type: transmission, min: 50000000000, max: 69999999999, minCharacterLength: 11, maxCharacterLength: 11 },
      ];

      const rule = accountRules.find((accRule) => accRule.type === data.accountType);

      if (!rule) {
        throw new Error('Invalid account type for First National Bank');
      }

      const { min, max, minCharacterLength, maxCharacterLength } = rule;
      const accountNumberInt = parseInt(accountNumber);

      if (isNaN(accountNumberInt) || accountNumberInt < min || accountNumberInt > max || accountNumber.length < minCharacterLength || accountNumber.length > maxCharacterLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid First National Bank account number',
          path: ['accountNumber'],
        });
      }
    }

    if (bankName === 'Nedbank') {
      const accountRules = [
        { type: savings, min: 2000000000, max: 2999999999, minCharacterLength: 10, maxCharacterLength: 10 },
        { type: current, min: 1000000000, max: 1999999999, minCharacterLength: 10, maxCharacterLength: 10 },
        { type: cheque, min: 1000000000, max: 1999999999, minCharacterLength: 10, maxCharacterLength: 10 },
        { type: transmission, min: 2000000000, max: 2999999999, minCharacterLength: 10, maxCharacterLength: 10 },
      ];

      const rule = accountRules.find((accRule) => accRule.type === data.accountType);

      if (!rule) {
        throw new Error('Invalid account type for NedBank bank');
      }

      const { min, max, minCharacterLength, maxCharacterLength } = rule;
      const accountNumberInt = parseInt(accountNumber);

      if (isNaN(accountNumberInt) || accountNumberInt < min || accountNumberInt > max || accountNumber.length < minCharacterLength || accountNumber.length > maxCharacterLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid Nedbank account number',
          path: ['accountNumber'],
        });
      }
    }

    if (bankName === 'Standard Bank') {
      const accountRules = [
        { type: savings, min: 0, max: 19999999999, minCharacterLength: 9, maxCharacterLength: 11 },
        { type: current, min: 0, max: 19999999999, minCharacterLength: 9, maxCharacterLength: 11 },
        { type: cheque, min: 0, max: 19999999999, minCharacterLength: 9, maxCharacterLength: 11 },
        { type: transmission, min: 0, max: 19999999999, minCharacterLength: 9, maxCharacterLength: 11 },
      ];

      const rule = accountRules.find((accRule) => accRule.type === data.accountType);

      if (!rule) {
        throw new Error('Invalid account type for Standard Bank');
      }

      const { min, max, minCharacterLength, maxCharacterLength } = rule;

      const accountNumberInt = parseInt(accountNumber);

      if (isNaN(accountNumberInt) || accountNumberInt < min || accountNumberInt > max || accountNumber.length < minCharacterLength || accountNumber.length > maxCharacterLength) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Invalid Standard Bank account number',
          path: ['accountNumber'],
        });
      }
    }

    return true;
  });

export const termsAndConditionsSchema = z.object({
  loanAgreementAccepted: z.boolean(),
  debitOrderAuthorisation: z.boolean(),
});

export const confirmPasswordResetSchema = z.object({
  email: z.string().email('Please enter a valid email address.'),
  newPassword: z.string(),
  confirmNewPassword: z.string().nonempty('This field is required'),
});

export const repaySchema = (currentBalance: number, minimumAmountInRands: number) =>
  z
    .object({
      repayAmount: z.string(),
    })
    .refine((data) => parseFloat(data.repayAmount) >= minimumAmountInRands, {
      message: `Must be R${minimumAmountInRands} or more`,
      path: ['repayAmount'],
    })
    .refine((data) => parseFloat(data.repayAmount) <= currentBalance, {
      message: `Must be R${currentBalance} or less`,
      path: ['repayAmount'],
    });

export const changeMobileNumberSchema = (previousMobileNumber: string) =>
  z
    .object({
      cellphoneNumber: mobileNumberConstraints,
    })
    .refine((data) => data.cellphoneNumber !== previousMobileNumber, {
      message: `This number already exists`,
      path: ['cellphoneNumber'],
    });

export const limiterFormSchema = z.object({
  firstname: z
    .string()
    .trim()
    .min(2, { message: 'Please enter a valid first name' })
    .max(75, { message: 'Please enter a valid first name' })
    .regex(new RegExp(RegularExpressions.noSpecialChars), {
      message: 'You have entered illegal characters in this field',
    })
    .regex(new RegExp(RegularExpressions.noNumeric), {
      message: 'No numbers allowed',
    }),
  lastname: z
    .string()
    .trim()
    .min(2, { message: 'Please enter a valid last name' })
    .max(75, { message: 'Please enter a valid last name' })
    .regex(new RegExp(RegularExpressions.noSpecialChars), {
      message: 'You have entered illegal characters in this field',
    })
    .regex(new RegExp(RegularExpressions.noNumeric), {
      message: 'No numbers allowed',
    }),
  email: newEmailContraints,
  cellphoneNumber: mobileNumberConstraints,
});

export const creditLifePlusBeneficiarySchema = z.object({
  firstname: z
    .string()
    .trim()
    .min(2, { message: 'Please enter a valid first name' })
    .max(75, { message: 'Please enter a valid first name' })
    .regex(new RegExp(RegularExpressions.noSpecialChars), {
      message: 'You have entered illegal characters in this field',
    })
    .regex(new RegExp(RegularExpressions.noNumeric), {
      message: 'No numbers allowed',
    }),
  lastname: z
    .string()
    .trim()
    .min(2, { message: 'Please enter a valid last name' })
    .max(75, { message: 'Please enter a valid last name' })
    .regex(new RegExp(RegularExpressions.noSpecialChars), {
      message: 'You have entered illegal characters in this field',
    })
    .regex(new RegExp(RegularExpressions.noNumeric), {
      message: 'No numbers allowed',
    }),
  beneficiaryRelationship: z
    .nativeEnum(BeneficiaryRelationshipEnum)
    .refine((value) => value !== BeneficiaryRelationshipEnum.Unspecified, {
      message: 'This field is required',
    }),
  cellphoneNumber: mobileNumberConstraints,
});

export const confirmIdNumberFormSchema = z.object({
  idNumber: z
    .string()
    .min(13, { message: 'This is not a valid RSA ID' })
    .max(13, { message: 'This is not a valid RSA ID' })
    .superRefine((id, ctx) => {
      const errorMessage = ValidateIdNumber(id);
      if (errorMessage !== undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${errorMessage}`,
        });
      }
    }),
});

export const confirmOTPFormSchema = z.object({
  otp: otpContraints,
});
