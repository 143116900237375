import { Box, Stack, Typography, Avatar } from "@mui/material";
import { theme } from "../../theme/Theme";
import closeIcon from "../../assets/img/Close.png"

interface EftPaymentInfoPopupProps {
    open: boolean;
    handleClose: () => void;
}

const EftPaymentInfoPopup: React.FunctionComponent<EftPaymentInfoPopupProps> = ({
    open,
    handleClose,
}: EftPaymentInfoPopupProps) => {

    const handleOnClose = () => {
        handleClose();
    };

    return (
        <Box
            display={open ? 'block' : 'none'}
            position={'absolute'}
            sx={{
                top: { xs: '8rem', md: '4.5rem' },
                left: 0,
                right: 0,
                marginLeft: { xs: 'auto', md: 'auto' },
                marginRight: { xs: 'auto', md: 'auto' },
                maxWidth: '35.7rem',
                borderRadius: '0.5rem',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                transition: 'all 0.25s ease-in-out',
                zIndex: '1',
            }}
        >
            <Stack
                flexDirection={'row'}
                alignItems={'center'}
                justifyContent={'space-between'}
                flexGrow={1}
                sx={{
                    padding: '0.4rem 1.3rem',
                    backgroundColor: theme.palette.secondary.main,
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem',
                }}
            >
                <Typography
                    fontSize={'1.8rem'}
                    fontWeight={500}
                    lineHeight={'4rem'}
                    color={theme.palette.common.white}
                    fontStyle={'normal'}
                >
                    EFT payment info
                </Typography>
                <Avatar
                    src={closeIcon}
                    alt={'Close Icon'}
                    sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
                    onClick={handleOnClose}
                />
            </Stack>

            <Box
                sx={{
                    backgroundColor: theme.palette.common.white,
                    padding: '1.6rem',
                    borderBottomLeftRadius: '0.5rem',
                    borderBottomRightRadius: '0.5rem',
                }}
            >
                <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} fontStyle={'normal'}>
                    To protect you and ensure we can allocate your payment to your loan account correctly, we{' '}
                    <Typography
                        component={'span'}
                        fontSize={'1.3rem'}
                        fontWeight={600}
                        lineHeight={'1.5rem'}
                        fontStyle={'normal'}
                    >
                        no longer support regular EFT{' '}
                    </Typography>
                    as a payment option.{' '}
                    Please choose an alternative secure payment option below.
                </Typography>
            </Box>
        </Box>
    );
};

export default EftPaymentInfoPopup;