import { useTracking } from '../../../../Tracking/TrackingContext';
import { useLocation } from 'react-router-dom';
import { navigateToDocumentUpload } from '../../../../utils/Helpers/NavigationHelper';
import { Container, Stack, Typography } from '@mui/material';
import { formatMoney } from '../../../../utils/Helpers/SliderHelperFunctions';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import styles from "./FailedOpenBanking.module.scss";
import { RouterRoutes } from '../../../../utils/RouterRoutes';
import useCustomNavigate from '../../../../hooks/Navigation';

export const FailedOpenBanking = () => {
  const { isExistingCustomer } = useTracking();
  const location = useLocation();
  const loanId = location && location.state ? location.state["loanId"] ?? "" : "";
  const loanAmount = location && location.state ? location.state["loanAmount"] : 0;
  const title = location && location.state ? location.state["title"] : "";
  const isConcurrentLoan = location.pathname.includes(RouterRoutes.concurrentJourney);
  const { navigateWithGclidParams } = useCustomNavigate();

  const redirectToDocumentUpload = () => {
    navigateToDocumentUpload(navigateWithGclidParams, loanId, isExistingCustomer, isConcurrentLoan);
  };

  return (
    <>
      <Container className={styles["uploadDoc-container"]}>
        <Typography className={styles["uploadDoc-title"]}>
          {title}
        </Typography>

        <Typography className={styles['loan-amount']}>
          <b>R {formatMoney(loanAmount ?? 0)}</b>
        </Typography>
        <p style={{ textAlign: "center" }}> Application Amount </p>

        <Typography style={{ paddingTop: '20px' }}>
          To proceed with your application, please upload your bank statements.
        </Typography>

        <Stack sx={{ marginTop: { xs: '50px', md: '100px' } }} direction={"row"} justifyContent={"right"}>
          <PrimaryButton onClick={redirectToDocumentUpload}>
            <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
              Upload documents
            </Typography>
          </PrimaryButton>
        </Stack>

      </Container>
    </>
  );
}
