export default class RegularExpressions {
    static password = "^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?!.*\\s).{12,}$";
    static email = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,3}$";
    static idNumber = "^(?!.*0{13})(\\d{13})*$";

    static mobilePhone = '^0[6-8][0-9]{8}$';
    static workPhone = '^0[1-9][0-9]{8}$';
    static otp = '^[0-9]{4,4}$';
    static textOnly = "^[A-Za-z \\s']{1,75}$";

    static lowerCase = "^[a-z]$";

    static upperCase = "[A-Z]";

    static singleNumberic = "d";

    static doubleNumberic = "d.*d";

    static numericOnly = "^[0-9]+$";

    static specialChar = "[^a-zA-Z\\d]";

    static noSpecialChars = "^[A-Za-z0-9 \\-'.]{1,75}$";

    static noNumeric = "^([^0-9]*)$";

    static alphaNumeric = "^[a-zA-Z0-9]*$"

    static atleastEightCharacters = "^.{8,}$";

    static atleastOneNumber = "[0-9]";

    static atleastOneUppercaseLetter = "[A-Z]";

    static atleastOneLowercaseLetter = "[a-z]";

    static onlyStandardCharacters = "^[\x20-\x7E]+$";
}
