/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocalizationProvider, CalendarPickerView, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller, useFormContext } from "react-hook-form";
import { FieldName } from "./types";
import { GlobalStyles, IconButton, InputAdornment } from "@mui/material";
import StyledTextField from "../TextField/StyledTextField";
import React from "react";
import CalendarIcon from "../../../assets/img/svg/Calendar.svg?react";

interface FormControlProps {
    name: FieldName;
    format: string;
    control: any;
    register: any;
    disabled?: boolean;
    views: CalendarPickerView[];
}

const DatePickerFormController: React.FC<FormControlProps> = (props) => {
    const { name, disabled, views, format } = props;
    const { control, formState } = useFormContext();

    return (<Controller
        name={name}
        control={control}
        render={({
            field,
        }) => (
            <>
                <GlobalStyles
                    styles={{
                        ".MuiPaper-root": {
                            borderRadius: '16px !important',
                        },
                        ".MuiDialogActions-root": {
                            borderTop: '1px solid #CAC4D0'
                        },
                        ".MuiDatePickerToolbar-root": {
                            paddingBottom: '0 !important'
                        },
                        ".MuiPickersToolbar-root .MuiTypography-root": {
                            fontSize: "1.4rem",
                            textTransform: 'capitalize'
                        },
                        ".MuiPickersToolbar-root .MuiPickersToolbar-content": {
                            display: 'none'
                        },
                        ".MuiPickersCalendarHeader-root .MuiPickersCalendarHeader-switchViewIcon": {
                            width: '2.5rem',
                            height: '2.5rem'
                        },
                        ".PrivatePickersYear-root button": {
                            fontSize: "1.4rem"
                        },
                        ".MuiMonthPicker-root button": {
                            fontSize: "1.4rem"
                        }
                    }}
                />
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                        {...field}
                        maxDate={new Date()}
                        disableFuture
                        inputFormat={format}
                        openTo={views[0]}
                        views={views}
                        disabled={disabled}
                        renderInput={(params) =>
                            <StyledTextField
                                {...params}
                                error={!!formState.errors[name]}
                                helperText={!!formState.errors[name] && formState.errors[name]?.message?.toString()}
                                placeholder={format.toLocaleLowerCase()}
                                inputProps={{
                                    ...params.inputProps,
                                    placeholder: format.toLocaleLowerCase(),
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton style={{ paddingBottom: '8px' }}>
                                                <CalendarIcon width={24} height={24} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }
                    />
                </LocalizationProvider>
            </>
        )}
    />);
};

export default DatePickerFormController;
