import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";


const StyledTextField = styled(TextField)(({ theme }) => ({
    borderRadius: '8px',
    outline: 'none',
    width: '100%',
    background: 'white',
    marginBottom: '1rem',
    fontSize: '15px',
    color: 'inherit',

    '& .MuiInputBase-input': {
        padding: '15px 15px 15px 28px', // padding for input itself
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // remove default border
    },
    '& .MuiOutlinedInput-root': {
        border: `1px solid ${theme.palette.grey[200]}`, // outline border around the entire input
        '& fieldset': {
            transition: 'border-left 0.15s linear',
            borderLeft: `6px solid #f5f5f5`, // default left border
        },

        // Focused state: blue border
        '&:focus-within fieldset': {
            borderLeft: '6px solid #008fd5',
        },

        '&:not(&.Mui-disabled):hover:focus-within fieldset': {
            borderLeft: '6px solid #008fd5',
        },

        // Filled state: green border when the input has a value and is not error and is not disabled
        '&:not(&.Mui-error):not(&.Mui-disabled) .MuiInputBase-input:not(:placeholder-shown)': {
            '& ~ fieldset': {
                borderLeft: '6px solid #7ac206',
            },
        },

        // Hover when error state is active: make sure the red border persists
        '&.Mui-error:hover fieldset': {
            borderLeft: '6px solid #d0021b',
        },

        // Blue border when focused, even if in error state
        '&.Mui-error:focus-within fieldset': {
            borderLeft: '6px solid #008fd5',
        },

        // Remove the default hover effect that adds a black border
        '&:hover fieldset': {
            borderLeft: `6px solid #f5f5f5`,
        },

        '&.Mui-disabled fieldset': {
            borderLeft: `6px solid #f5f5f5`,
        },
    },
    '& .MuiFormHelperText-root': {
        color: '#d0021b',
        fontSize: '14px',
        marginTop: '1rem',
        height: '1rem',
        textAlign: 'end'
    },

    // Hide helper text when both error and focus-within are active
    '& .MuiOutlinedInput-root:focus-within.Mui-error ~ .MuiFormHelperText-root': {
        visibility: 'hidden',
    },

    // Remove margim bottom whem error
    '& .MuiOutlinedInput-root.Mui-error': {
        marginBottom: 0
    },

    '& .MuiOutlinedInput-root .MuiInputAdornment-outlined .MuiSvgIcon-root': {
        width: '24px',
        height: '24px',
    }
}));

export default StyledTextField;