import { Button } from '@mui/material';
import React, { ReactNode } from 'react';

interface PrimaryButtonProps {
  children: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  fullWidth?: boolean;
  height?: string | undefined
}

const PrimaryButton = ({ type, onClick, disabled, children, fullWidth, height }: PrimaryButtonProps) => {
  return (
    <Button
      disabled={disabled}
      color="secondary"
      variant="contained"
      type={type}
      onClick={onClick}
      sx={{
        width: fullWidth ? { xs: '100%' } : { md: '26rem', xs: '100%' },
        height: height ?? '5rem',
        borderRadius: '0.8rem',
        fontSize: '1.5rem',
        fontWeight: 500,
        boxShadow: 'none',
        ':hover': {
          boxShadow: 'none',
        },
      }}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
