import { Route, Routes, useLocation } from 'react-router-dom';
import TrackingContextProvider, { useTracking } from './Tracking/TrackingContext';
import AppBar from './components/AppBar/AppBar';
import WongaFooter from './components/Footer/WongaFooter';
import UrgentNotificationBanner from './components/UrgentNotificationBanner/UrgentNotificationBanner';
import { AppSettingsContextProvider, useAppSettings } from './contexts/AppSettingsContext';
import AuthContextProvider, { RequireAuth } from './contexts/AuthContext';
import LimiterProvider from './contexts/LimiterContext';
import PaymentDialogProvider from './contexts/PaymentDialogContext';
import SeoContextProvider from './contexts/SeoContext';
import SnackBarProvider from './contexts/SnackBarContext';
import Home from './pages/Home/Home';
import AccountSummary from './pages/MyLoan/AccountSummary/AccountSummary';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import { RouterRoutes } from './utils/RouterRoutes';
import { PostProcessing, concurrentLoanJourney, l0journey, lnJourney, lnJourneyShortened } from './pages/Wizard/JourneyConfig/JourneyConfigs';
import Limiter from './pages/Wizard/JourneyPages/Limiter';
import Base from './components/Base/base';
import SliderWithFlap from './components/Sliders/slider-with-flap/slider-with-flap';
import { lazy } from 'react';
import Wizard from './pages/Wizard/Wizard';
import LoanDocuments from './pages/MyLoan/LoanDocuments/LoanDocument';
import { ConfigCatProvider, LogLevel } from 'configcat-react';
import { getPollingMode } from './configCat/utils';
import Landing from './pages/MyLoan/Landing/Landing';

const MyLoan = lazy(() => import('./pages/MyLoan/MyLoan'));
const MyDetails = lazy(() => import('./pages/MyDetails/MyDetails'));

const StatementPrint = lazy(() => import('./pages/MyLoan/LoanDocuments/Statements/StatementPrint/StatementPrint'));
const PaidUpLetterPrint = lazy(
  () => import('./pages/MyLoan/LoanDocuments/PaidUpLetters/PaidUpLetterPrint/PaidUpLetterPrint')
);
const LegalDocumentPrint = lazy(
  () => import('./pages/MyLoan/LoanDocuments/LegalDocuments/LegalDocumentPrint/LegalDocumentPrint')
);
const SettlementLetterPdfView = lazy(
  () => import('./pages/MyLoan/AccountSummary/SettlementLetterPdf/SettlementLetterPdfView')
);

const Register = lazy(() => import('./pages/Register/Register'));
const Login = lazy(() => import('./pages/Login/Login'));
const ConfirmIDNumber = lazy(() => import('./pages/Login/ViaOTP/ConfirmIDNumber'));
const ConfirmOTP = lazy(() => import('./pages/Login/ViaOTP/ConfirmOTP'));
const MissingCellNumber = lazy(() => import('./pages/Login/ViaOTP/MissingCellNumber'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const ForgotPasswordEmailResend = lazy(() => import('./pages/ForgotPassword/ForgotPasswordEmailResend'));
const ConfirmPasswordReset = lazy(() => import('./pages/ForgotPassword/ConfirmPasswordReset/ConfirmPasswordReset'));
const ConfirmChangeEmail = lazy(() => import('./pages/ChangeEmail/ConfirmChangeEmail/ConfirmChangeEmail'));
const LeadGenRedirect = lazy(() => import('./pages/Wizard/JourneyPages/LeadGen/LeadGenRedirect'));

function App() {
  const { TrackError } = useTracking();
  const appSettings = useAppSettings();
  const location = useLocation();
  const showHeader = !(
    location.pathname.includes(RouterRoutes.statementPrint) ||
    location.pathname.includes(RouterRoutes.paidUpLetterPrint) ||
    location.pathname.includes(RouterRoutes.legalDocumentPrint) ||
    location.pathname.includes(RouterRoutes.settlementLetterPrint)
  );
  const showFooter = !(
    location.pathname.includes(RouterRoutes.statementPrint) ||
    location.pathname.includes(RouterRoutes.paidUpLetterPrint) ||
    location.pathname.includes(RouterRoutes.legalDocumentPrint) ||
    location.pathname.includes(RouterRoutes.settlementLetterPrint) ||
    location.pathname.includes(RouterRoutes.newJourney) ||
    location.pathname.includes(RouterRoutes.existingJourney) ||
    location.pathname.includes(RouterRoutes.concurrentJourney)
  );

  const logger = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    log: (level: LogLevel, eventId: number, message: string, exception?: any) => {
      if (level === LogLevel.Error) {
        TrackError(exception ?? message, `ConfigCat Error (Event ID: ${eventId})`);
      }
    },
  };

  return (
    /* eslint-disable @typescript-eslint/no-empty-function */
    <AppSettingsContextProvider>
      <TrackingContextProvider>
        <ConfigCatProvider sdkKey={appSettings.configCat.sdkKey} pollingMode={getPollingMode(appSettings.configCat.pollingMode)} options={{
          logger, pollIntervalSeconds: appSettings.configCat.pollingInterval, setupHooks(hooks) {
            hooks.on('clientReady', () => console.log("Client is Ready"))
          },
          baseUrl: appSettings.configCat.baseUrl
        }}>
          <LimiterProvider>
            <SnackBarProvider>
              <AuthContextProvider>
                <SeoContextProvider>
                  <UrgentNotificationBanner />

                  {showHeader && <AppBar />}
                  <Routes>
                    <Route path={RouterRoutes.home} element={<Home />}>
                      <Route path={RouterRoutes.home} element={<SliderWithFlap updateLoanValues={() => { }} />} />
                    </Route>

                    <Route path={RouterRoutes.home} element={<Base />}>
                      <Route path={RouterRoutes.limiter} element={<Limiter />} />
                      <Route path={RouterRoutes.login} element={<Login />} />
                      <Route path={RouterRoutes.confirmIdNumber} element={<ConfirmIDNumber />} />
                      <Route path={RouterRoutes.confirmOTP} element={<ConfirmOTP />} />
                      <Route path={RouterRoutes.MissingCellNumber} element={<MissingCellNumber />} />
                      <Route path={RouterRoutes.register} element={<Register />} />

                      <Route path={RouterRoutes.confirmChangeEmail + '/:pin'} element={<ConfirmChangeEmail />} />
                      <Route path={RouterRoutes.recoverPassword} element={<ForgotPassword />} />
                      <Route path={RouterRoutes.confirmPasswordReset + '/:pin'} element={<ConfirmPasswordReset />} />

                      <Route path={RouterRoutes.newJourney} element={<Wizard />}>
                        {l0journey.map((config, index) => (
                          <Route key={index} path={config.path} element={config.element} />
                        ))}
                        {PostProcessing.map((config, index) => (
                          <Route key={index} path={config.path} element={config.element} />
                        ))}
                      </Route>
                      <Route path={RouterRoutes.existingJourney} element={<Wizard />}>
                        {[...lnJourney, ...lnJourneyShortened].map((config, index) => (
                          <Route key={index} path={config.path} element={config.element} />
                        ))}
                        {PostProcessing.map((config, index) => (
                          <Route key={index} path={config.path} element={config.element} />
                        ))}
                      </Route>
                      <Route path={RouterRoutes.concurrentJourney} element={<Wizard />}>
                        {concurrentLoanJourney.map((config, index) => (
                          <Route key={index} path={config.path} element={config.element} />
                        ))}
                        {PostProcessing.map((config, index) => (
                          <Route key={index} path={config.path} element={config.element} />
                        ))}
                      </Route>

                      {/* My loan */}
                      <Route
                        path={RouterRoutes.myLoan + '/*'}
                        element={
                          <RequireAuth>
                            <MyLoan />
                          </RequireAuth>
                        }
                      >
                        <Route index element={<Landing />} />
                        <Route
                          path={':loanId/*'}
                          element={
                            <PaymentDialogProvider>
                              <AccountSummary />
                            </PaymentDialogProvider>
                          }
                        />
                        <Route path={RouterRoutes.loanDocuments} element={<LoanDocuments />} />
                      </Route>

                      {/* My Profile */}
                      <Route
                        path={RouterRoutes.myDetails + '/*'}
                        element={
                          <RequireAuth>
                            <MyDetails />
                          </RequireAuth>
                        }
                      />

                      <Route path={RouterRoutes.statementPrint} element={<StatementPrint />} />
                      <Route path={RouterRoutes.paidUpLetterPrint} element={<PaidUpLetterPrint />} />
                      <Route path={RouterRoutes.legalDocumentPrint} element={<LegalDocumentPrint />} />
                      <Route path={RouterRoutes.settlementLetterPrint} element={<SettlementLetterPdfView />} />

                      <Route path={RouterRoutes.emailResend} element={<ForgotPasswordEmailResend />} />
                      <Route path={RouterRoutes.leadAccept} element={<LeadGenRedirect />} />
                      <Route path="*" element={<PageNotFound />} />
                    </Route>
                  </Routes>
                  {showFooter && <WongaFooter />}
                </SeoContextProvider>
              </AuthContextProvider>
            </SnackBarProvider>
          </LimiterProvider>
        </ConfigCatProvider>
      </TrackingContextProvider>
    </AppSettingsContextProvider >
  );
}

export default App;
