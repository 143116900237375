import { AmountRange, QuotationResult, SliderConfig } from "./Types";

export enum ProductIdEnum {
    shortTermLoan = "c45acba1-aa1e-4d1f-8f60-8a745f9fddc0",
    instalmentLoan = "84ef968a-9796-449d-8f99-de4a605148de"
}

export const installmentDurationType = "month";
export const shortTermDurationType = "day";
export const initialSliderConfig: SliderConfig = {
    shortTermLoanConfig: {
        productId: "c45acba1-aa1e-4d1f-8f60-8a745f9fddc0",
        termMax: 42,
        termMin: 6,
        amountMax: 5000,
        amountMin: 500,
        limitsPerDuration: []
    },
    installmentLoanConfig: {
        productId: "84ef968a-9796-449d-8f99-de4a605148de",
        termMax: 3,
        termMin: 2,
        amountMax: 5000,
        amountMin: 500,
        limitsPerDuration: [
            {
                duration: 2,
                amountMax: 5000,
                amountMin: 500
            },
            {
                duration: 3,
                amountMax: 5000,
                amountMin: 600
            }
        ]
    }
}

export const initialQuotationResults: QuotationResult = {
    totalRepay: 0,
    initiationFee: 0,
    serviceFee: 0,
    totalFees: 0,
    totalInterest: 0,
    insuranceFee: 0,
}

export const initialAmountRange: AmountRange = {
    min: 500,
    max: 5000,
}