import React from 'react';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import styles from './Eft.module.scss';
import lightbulb from '../../../../../assets/img/svg/lightbulb-big.svg';
import { IOpenLoanDetailsResponse } from '../../../../../clients/LoanClient';
import { useParams } from 'react-router-dom';
import { formatMoney } from '../../../../../utils/Helpers/SliderHelperFunctions';
import CopyIcon from '../../../../../assets/img/copy_icon.png';
import { formatDateWithDefault } from '../../../../../utils/Helpers/DateHelpers';
import TextButton from '../../../../../components/Buttons/TextButton';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import { RouterRoutes } from '../../../../../utils/RouterRoutes';
import NavigateChevronLink from '../../../../../components/NavigateChevronLink/NavigateChevronLink';
import EftDeprecationBanner from '../../../../../components/EftDeprecationBanner/EftDeprecationBanner';

interface EftProps {
  openLoanResponse: IOpenLoanDetailsResponse;
}

interface RowProps {
  title: string;
  value: string;
  showCopy?: boolean;
  bold?: boolean;
}

const Eft: React.FunctionComponent<EftProps> = ({ openLoanResponse }: EftProps) => {
  const { currentBalance, accountNumber } = openLoanResponse.loanData || {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { displaySnackBar } = useSnackBar();
  const { loanId } = useParams();

  const bankDetails: RowProps[] = [
    { title: 'Bank', value: 'Absa' },
    { title: 'Branch Code', value: '631009' },
    { title: 'Account number', value: '4077955749', showCopy: true },
    { title: 'Beneficiary reference', value: accountNumber ?? "", showCopy: true, bold: true },
  ];

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
    displaySnackBar("Copied to clipboard", "success");
  }

  return (
    <div className={styles['eft-container']}>
      <NavigateChevronLink route={`/${RouterRoutes.myLoan}/${loanId}/${RouterRoutes.repay}`}>Back</NavigateChevronLink>
      <EftDeprecationBanner />
      <Typography
        variant="h1"
        fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
        fontWeight={400}
        lineHeight={'3.2rem'}
        letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
      >
        Repay using EFT
      </Typography>
      <div className={styles['eft-hint-container']}>
        <img src={lightbulb} aria-label="Lightbulb Icon" className={styles['eft-hint-icon']} />
        <Typography fontWeight={300} fontSize={'1.5rem'} component={'span'}>
          Please note that repayments made by EFT that are within
          <Typography fontWeight={500} component={'span'}>
            {" "}5 business days{" "}
          </Typography>
          of your next debit order collection
          <Typography fontWeight={500} component={'span'}>
            {" "}may not be processed in time.{" "}
          </Typography>
          Once payment is made, this can take
          <Typography fontWeight={500} component={'span'}>
            {" "}2-3 business days{" "}
          </Typography>
          to reflect on your loan account.
        </Typography>
      </div>
      <Typography
        variant="body1"
        fontSize={'3.2rem'}
        fontWeight={600}
        lineHeight={'2.2rem'}
        letterSpacing={'-0.04em'}
        textAlign={'center'}
        marginTop={isMobile ? '2.4rem' : '3.2rem'}
        color={theme.palette.primary.dark}
      >
        R {formatMoney(currentBalance ?? 0)}
      </Typography>
      <Stack
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        marginTop={'1.1rem'}
        marginBottom={'2rem'}
      >
        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={400} lineHeight={'1.7rem'}>
          Settlement balance
        </Typography>
        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={300} lineHeight={'1.7rem'}>
          {`${formatDateWithDefault(undefined, 'dd MMM yyyy')}`}
        </Typography>
      </Stack>
      {bankDetails.map((item, index) => (
        <div key={index} className={index === 0 ? styles['eft-table-first-row'] : styles['eft-table']}>
          <Typography fontSize={'1.5rem'} fontWeight={item.bold ? 500 : 300} lineHeight={'2.2rem'} letterSpacing={'-0.4px'}>
            {item.title}
          </Typography>
          <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={'1.2rem'}>
            <Typography fontSize={'1.5rem'} fontWeight={item.bold ? 500 : 300} lineHeight={'2.2rem'} letterSpacing={'-0.4px'}>
              {item.value}
            </Typography>
            {
              item.showCopy &&
              <TextButton removePadding onClick={() => copyToClipboard(item.value)}>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} marginTop={'-0.2rem'}>
                  <img src={CopyIcon} aria-label="Copy Icon" style={{ marginRight: '0.4rem', height: '1.5rem', width: '1.2rem' }} />
                  <Typography fontSize={'1.2rem'} fontWeight={300} marginTop={'0.2rem'}>
                    Copy
                  </Typography>
                </Stack>
              </TextButton>
            }
          </Stack>
        </div>
      ))}
    </div>
  );
};

export default Eft;
