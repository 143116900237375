import React from 'react';
import AddressDetailsPage from '../JourneyPages/AddressDetails';
import AffordabilityDetailsPage from '../JourneyPages/AffordibilityDetails';
import BankDetailsPage from '../JourneyPages/BankDetails';
import OtpPage from '../JourneyPages/Otp';
import EmploymentDetailsPage from '../JourneyPages/EmploymentDetails';
import CustomerDetailsPage from '../JourneyPages/CustomerDetails';
import ConfirmCustomerDetails from '../JourneyPages/LN/ConfirmCustomerDetails/ConfirmCustomerDetails';
import ConfirmAffordabilityDetailsPage from '../JourneyPages/LN/ConfirmAffordabilityDetails/ConfirmAffordabilityDetails';
import ConfirmDetailsSummary from '../JourneyPages/LN/ConfirmDetailsSummary/ConfirmDetailsSummary';
import {
  ConcurrentLoanJourneyRoutes,
  L0JourneyRoutes,
  LNJourneyRoutes,
  LNJourneyShortenedRoutes,
  PostProcessingRoutes
} from '../../../utils/RouterRoutes';
import Processing from '../JourneyPages/Processing';
import TermsAndConditionsPage from '../JourneyPages/Decisions/Approved/TermsAndConditions';
import Declined from '../JourneyPages/Decisions/Declined/Declined';
import CounterofferProcessing from '../JourneyPages/Decisions/counteroffer/CounterofferProcessing';
import CounterOffer from '../../MyLoan/AccountSummary/CounterOffer/CounterOffer';
import CounterOfferDeclined from '../JourneyPages/Decisions/Declined/CounterOfferDeclined';
import CreditLifePlus from '../JourneyPages/VAS/CreditLifePlus/CreditLifePlus';
import CreditLifePlusBeneficiary from '../JourneyPages/VAS/CreditLifePlus/CreditLifePlusBeneficiary';
import { defaultAppSettings } from '../../../contexts/AppSettingsContext';
import DocumentUploadOld from '../../../components/DocumentUpload/DocumentUploadOld';
import DocumentUploadNew from '../../../components/DocumentUpload/DocumentUploadNew';

import CreditLifeLoanRepaymentDetails from '../JourneyPages/VAS/CreditLifePlus/CreditLifeLoanRepaymentDetails';
import { IvOptions } from '../JourneyPages/IncomeVerification/IvOptions';
import { OpenBankingProcessing } from '../JourneyPages/IncomeVerification/OpenBankingProcessing';
import { FailedOpenBanking } from '../JourneyPages/IncomeVerification/FailedOpenBanking';
import ConcurrentLoanOffer from '../JourneyPages/ConcurrentLoan/ConcurrentLoanOffer';
import { CancelledOpenBanking } from '../JourneyPages/IncomeVerification/CancelledOpenBanking';
export interface JourneyConfig<RouteTypeEnum> {
  path: RouteTypeEnum;
  element: React.ReactElement;
}

export const l0journey: JourneyConfig<L0JourneyRoutes>[] = [
  { path: L0JourneyRoutes.otp, element: <OtpPage /> },
  { path: L0JourneyRoutes.details, element: <CustomerDetailsPage /> },
  { path: L0JourneyRoutes.address, element: <AddressDetailsPage /> },
  { path: L0JourneyRoutes.employment, element: <EmploymentDetailsPage /> },
  { path: L0JourneyRoutes.affordability, element: <AffordabilityDetailsPage /> },
  { path: L0JourneyRoutes.bank, element: <BankDetailsPage /> },
  { path: L0JourneyRoutes.creditLifePlus, element: <CreditLifePlus /> },
  { path: L0JourneyRoutes.creditLifeLoanRepaymentDetails, element: <CreditLifeLoanRepaymentDetails /> },
  { path: L0JourneyRoutes.creditLifePlusBeneficiary, element: <CreditLifePlusBeneficiary /> },
  { path: L0JourneyRoutes.processing, element: <Processing /> },
]
  .filter((routes) =>
    !defaultAppSettings.insurance.deathBenefitIncludeBeneficiaryDetails
      ? routes.path !== L0JourneyRoutes.creditLifePlusBeneficiary
      : true
  )
  .filter((x) =>
    !defaultAppSettings.insurance.deathBenefitEnabled
      ? x.path !== L0JourneyRoutes.creditLifePlus &&
      x.path !== L0JourneyRoutes.creditLifePlusBeneficiary &&
      x.path !== L0JourneyRoutes.creditLifeLoanRepaymentDetails
      : true
  );

export const lnJourney: JourneyConfig<LNJourneyRoutes>[] = [
  { path: LNJourneyRoutes.otp, element: <OtpPage /> },
  { path: LNJourneyRoutes.details, element: <CustomerDetailsPage /> },
  { path: LNJourneyRoutes.address, element: <AddressDetailsPage /> },
  { path: LNJourneyRoutes.employment, element: <EmploymentDetailsPage /> },
  { path: LNJourneyRoutes.affordability, element: <AffordabilityDetailsPage /> },
  { path: LNJourneyRoutes.bank, element: <BankDetailsPage /> },
  { path: LNJourneyRoutes.creditLifePlus, element: <CreditLifePlus /> },
  { path: LNJourneyRoutes.creditLifeLoanRepaymentDetails, element: <CreditLifeLoanRepaymentDetails /> },
  { path: LNJourneyRoutes.creditLifePlusBeneficiary, element: <CreditLifePlusBeneficiary /> },
  { path: LNJourneyRoutes.processing, element: <Processing /> },
]
  .filter((routes) =>
    !defaultAppSettings.insurance.deathBenefitIncludeBeneficiaryDetails
      ? routes.path !== LNJourneyRoutes.creditLifePlusBeneficiary
      : true
  )
  .filter((x) =>
    !defaultAppSettings.insurance.deathBenefitEnabled
      ? x.path !== LNJourneyRoutes.creditLifePlus &&
      x.path !== LNJourneyRoutes.creditLifePlusBeneficiary &&
      x.path !== LNJourneyRoutes.creditLifeLoanRepaymentDetails
      : true
  );

export const lnJourneyShortened: JourneyConfig<LNJourneyShortenedRoutes>[] = [
  { path: LNJourneyShortenedRoutes.confirmDetails, element: <ConfirmDetailsSummary /> },
  { path: LNJourneyShortenedRoutes.updateDetails, element: <ConfirmCustomerDetails /> },
  { path: LNJourneyShortenedRoutes.updateAffordability, element: <ConfirmAffordabilityDetailsPage /> },
  { path: LNJourneyShortenedRoutes.creditLifePlus, element: <CreditLifePlus /> },
  { path: LNJourneyShortenedRoutes.creditLifeLoanRepaymentDetails, element: <CreditLifeLoanRepaymentDetails /> },
  { path: LNJourneyShortenedRoutes.creditLifePlusBeneficiary, element: <CreditLifePlusBeneficiary /> },
  { path: LNJourneyShortenedRoutes.processing, element: <Processing /> },
]
  .filter((routes) =>
    !defaultAppSettings.insurance.deathBenefitIncludeBeneficiaryDetails
      ? routes.path !== LNJourneyShortenedRoutes.creditLifePlusBeneficiary
      : true
  )
  .filter((x) =>
    !defaultAppSettings.insurance.deathBenefitEnabled
      ? x.path !== LNJourneyShortenedRoutes.creditLifePlus &&
      x.path !== LNJourneyShortenedRoutes.creditLifePlusBeneficiary &&
      x.path !== LNJourneyShortenedRoutes.creditLifeLoanRepaymentDetails
      : true
  );

export const concurrentLoanJourney: JourneyConfig<ConcurrentLoanJourneyRoutes>[] = [
  { path: ConcurrentLoanJourneyRoutes.concurrentLoanOffer, element: <ConcurrentLoanOffer /> },
  { path: ConcurrentLoanJourneyRoutes.confirmDetails, element: <ConfirmDetailsSummary /> },
  { path: ConcurrentLoanJourneyRoutes.updateDetails, element: <ConfirmCustomerDetails /> },
  { path: ConcurrentLoanJourneyRoutes.updateAffordability, element: <ConfirmAffordabilityDetailsPage /> },
  { path: ConcurrentLoanJourneyRoutes.creditLifePlus, element: <CreditLifePlus /> },
  { path: ConcurrentLoanJourneyRoutes.creditLifeLoanRepaymentDetails, element: <CreditLifeLoanRepaymentDetails /> },
  { path: ConcurrentLoanJourneyRoutes.creditLifePlusBeneficiary, element: <CreditLifePlusBeneficiary /> },
  { path: ConcurrentLoanJourneyRoutes.processing, element: <Processing /> },
]
  .filter((routes) =>
    !defaultAppSettings.insurance.deathBenefitIncludeBeneficiaryDetails
      ? routes.path !== ConcurrentLoanJourneyRoutes.creditLifePlusBeneficiary
      : true
  )
  .filter((x) =>
    !defaultAppSettings.insurance.deathBenefitEnabled
      ? x.path !== ConcurrentLoanJourneyRoutes.creditLifePlus &&
      x.path !== ConcurrentLoanJourneyRoutes.creditLifePlusBeneficiary &&
      x.path !== ConcurrentLoanJourneyRoutes.creditLifeLoanRepaymentDetails
      : true
  );

export const PostProcessing: JourneyConfig<PostProcessingRoutes>[] = [
  { path: PostProcessingRoutes.agreement, element: <TermsAndConditionsPage /> },
  { path: PostProcessingRoutes.documentUpload, element: defaultAppSettings.pageToggles.newDocumentUploadPage ? < DocumentUploadNew /> : <DocumentUploadOld /> },
  { path: PostProcessingRoutes.declined, element: <Declined /> },
  { path: PostProcessingRoutes.counterOffer, element: <CounterOffer /> },
  { path: PostProcessingRoutes.counterOfferDecline, element: <CounterOfferDeclined /> },
  { path: PostProcessingRoutes.counterOfferProcessing, element: <CounterofferProcessing /> },
  { path: PostProcessingRoutes.IvOptions, element: <IvOptions /> },
  { path: PostProcessingRoutes.OpenBankingProcessing, element: <OpenBankingProcessing /> },
  { path: PostProcessingRoutes.OpenBankingFailure, element: <FailedOpenBanking /> },
  { path: PostProcessingRoutes.OpenBankingCancelled, element: <CancelledOpenBanking /> }
];
