import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import Loading from '../../../components/Loading/Loading';
import { Outlet, Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import styles from './AccountSummary.module.scss';
import './../../../styles/_buttons.scss';
import useAccountClient from '../../../hooks/account/Client';
import useLoanClient from '../../../hooks/loan/Client';
import Typography from '@mui/material/Typography';
import Disbursing from './Disbursing/Disbursing';
import {
  ErrorResponse,
  IGetDebitOrderScheduleResponse,
  IGetRepayStatusResponse,
  IHasSettlementLetterResponse,
  IOpenLoanDetailsResponse,
} from '../../../clients/LoanClient';
import AccountTimeline from './AccountTimeline/AccountTimeline';
import ActiveLoan from './ActiveLoan/ActiveLoan';
import UploadDoc from './UploadDoc/UploadDoc';
import LoanAgreement from './LoanAgreement/LoanAgreement';
import DoNotEngage from './DoNotEngage/DoNotEngage';
import { RouterRoutes } from '../../../utils/RouterRoutes';
import Eft from './Repay/Eft/Eft';
import EasyPay from './Repay/EasyPay/EasyPay';
import InstantEft from './Repay/InstantEft/InstantEft';
import Arrears from './Arrears/Arrears';
import CounterOffer from './CounterOffer/CounterOffer';
import Timeout from './Timeout/Timeout';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import HowItWorks from './HowItWorks/HowItWorks';
import { Box, Container } from '@mui/material';
import { useTracking } from '../../../Tracking/TrackingContext';
import SliderWithFlap from '../../../components/Sliders/slider-with-flap/slider-with-flap';
import OptimonkContainer from '../../../components/OptimonkContainer/OptimonkContainer';
import { validate } from 'uuid';

const Repay = lazy(() => import('./Repay/Repay'));
import { navigateToCancelledOpenBanking, navigateToFailedOpenBanking, navigateToOpenBankingProcessing } from '../../../utils/Helpers/NavigationHelper';
import Card from './Repay/Card/Card';
import CapitecPay from './Repay/CapitecPay/CapitecPay';
import { useMyLoanContext } from '../MyLoanContext';
import NavigateChevronLink from '../../../components/NavigateChevronLink/NavigateChevronLink';
import { getFriendlyOpenLoanStatus, OpenLoanStatusEnum } from '../../../utils/Helpers/OpenLoanStatusEnum';
import useCustomNavigate from '../../../hooks/Navigation';

const AccountSummary: React.FunctionComponent = () => {
  const { loanId } = useParams();
  const { personalDetailsResponse, refreshLoansSummary } = useMyLoanContext();

  const [isLoading, setIsLoading] = useState(true);
  const [openLoanDetailsResponse, setOpenLoanDetailsResponse] = useState<IOpenLoanDetailsResponse | null>(null);
  const [openLoanStatus, setOpenLoanStatus] = useState<OpenLoanStatusEnum>(OpenLoanStatusEnum.Unspecified);
  const [repayStatusResponse, setRepayStatusResponse] = useState<IGetRepayStatusResponse | null>(null);
  const [hasSettlementLetter, setHasSettlementLetter] = useState<IHasSettlementLetterResponse | null>(null);
  const [debitOrderScheduleResponse, setDebitOrderScheduleResponse] = useState<IGetDebitOrderScheduleResponse | null>(
    null
  );
  const accountClient = useAccountClient();
  const loanClient = useLoanClient();
  const [creditLimit, setCreditLimit] = useState(5000);
  const { displaySnackBar } = useSnackBar();
  const { GetAccountSummaryPageKey, TrackError } = useTracking();
  const showAccountSummaryContent = openLoanDetailsResponse?.hasOpenLoan && !openLoanDetailsResponse?.isDne;
  const showOptimonkContainer: boolean =
    (!isLoading &&
      (openLoanStatus === OpenLoanStatusEnum.PendingIncomeAndBankVerification ||
        openLoanStatus === OpenLoanStatusEnum.Disbursing ||
        openLoanDetailsResponse?.isDne)) ??
    false;
  const showOffersText: boolean =
    (!isLoading && (openLoanStatus === OpenLoanStatusEnum.Disbursing || openLoanDetailsResponse?.isDne)) ?? false;
  const { isExistingCustomer } = useTracking();
  const isConcurrentLoan = openLoanDetailsResponse?.quotationData?.isConcurrentLoan ?? false;
  const { appendPageKeyWithGclidParams } = useCustomNavigate();
  const { navigateWithGclidParams } = useCustomNavigate();

  const [searchParams] = useSearchParams();
  const openBankingStatusCode = searchParams.get('status');

  //We are appending the page key so that marketing can track the url on GTM
  const appendPageKeyToUrl = useCallback(() => {
    if (openLoanDetailsResponse && showAccountSummaryContent) {
      const pageKey = GetAccountSummaryPageKey(isConcurrentLoan, openLoanDetailsResponse.openLoanStatus);
      //using the window object to append the page key to the url because we don't want react to re-render the same component
      if (pageKey) appendPageKeyWithGclidParams(`/${RouterRoutes.myLoan}/${loanId}/${pageKey}`);
    }
  }, [openLoanDetailsResponse, showAccountSummaryContent, GetAccountSummaryPageKey, isConcurrentLoan, loanId, appendPageKeyWithGclidParams]);

  const fetchData = useCallback(async () => {
    if (!validate(loanId)) {
      navigateWithGclidParams(`/${RouterRoutes.myLoan}`);
      return;
    }

    try {
      const openLoanDetailsResponse = await loanClient.getOpenLoanDetails(loanId);

      const friendlyOpenLoanStatus = getFriendlyOpenLoanStatus(openLoanDetailsResponse.openLoanStatus);
      setOpenLoanStatus(friendlyOpenLoanStatus);

      if (friendlyOpenLoanStatus === OpenLoanStatusEnum.Active && openLoanDetailsResponse.loanData?.loanId) {
        const debitOrderScheduleResponse = await loanClient.getDebitOrderSchedule(
          openLoanDetailsResponse.loanData?.loanId
        );
        setDebitOrderScheduleResponse(debitOrderScheduleResponse);
      }

      setOpenLoanDetailsResponse(openLoanDetailsResponse);

      if (openLoanDetailsResponse.hasOpenLoan && openLoanDetailsResponse.loanData?.loanId) {
        const repayStatusResponsePromise = loanClient.getRepayStatus(openLoanDetailsResponse.loanData.loanId);
        const hasSettlementLetterPromise = loanClient.hasSettlementLetter(openLoanDetailsResponse.loanData.loanId);
        const [repayStatusResponse, hasSettlementLetterResponse] = await Promise.all([
          repayStatusResponsePromise,
          hasSettlementLetterPromise,
        ]);

        setRepayStatusResponse(repayStatusResponse);
        setHasSettlementLetter(hasSettlementLetterResponse);
      }
      setIsLoading(false);
    } catch (error) {
      // Bad Request indicates that loan either is no longer open or there is a loan_id mismatch with the account_id
      if (error instanceof ErrorResponse && error.errorCode === 400) {
        TrackError(error, error.message);
        await refreshLoansSummary();
        navigateWithGclidParams(`/${RouterRoutes.myLoan}`)
        return;
      } else {
        displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
      }
    }
    // eslint-disable-next-line
  }, [accountClient, loanClient, displaySnackBar]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    appendPageKeyToUrl();
  }, [appendPageKeyToUrl]);

  useEffect(() => {
    const loanId = openLoanDetailsResponse?.loanData?.loanId;
    if (openBankingStatusCode && loanId) {
      const statusCode = Number(openBankingStatusCode);
      if (statusCode <= 2000) {
        navigateToOpenBankingProcessing(navigateWithGclidParams, loanId, isExistingCustomer, isConcurrentLoan);
      }
      else if (statusCode === 4000 || statusCode === 5000)
      {
        navigateToCancelledOpenBanking(navigateWithGclidParams, loanId, isExistingCustomer, isConcurrentLoan);
      }
      else {
        const title = "We couldn’t successfully connect to your bank.";
        navigateToFailedOpenBanking(navigateWithGclidParams, loanId, isExistingCustomer, openLoanDetailsResponse?.quotationData?.loanAmount ?? 0, title, isConcurrentLoan)
      }
    }
  }, [isConcurrentLoan, isExistingCustomer, navigateWithGclidParams, openBankingStatusCode, openLoanDetailsResponse?.loanData?.loanId, openLoanDetailsResponse?.quotationData?.loanAmount]);

  // Redirect drop-offs to the open-banking processing screen if still processing
  useEffect(() => {
    const loanId = openLoanDetailsResponse?.loanData?.loanId;
    if (openLoanDetailsResponse?.loanStateResponse?.avaInfo?.pendingOpenBankingOutcome && loanId) {
      navigateToOpenBankingProcessing(navigateWithGclidParams, loanId, isExistingCustomer, isConcurrentLoan);
    }
  }, [isConcurrentLoan, isExistingCustomer, navigateWithGclidParams, openLoanDetailsResponse?.loanData?.loanId, openLoanDetailsResponse?.loanStateResponse?.avaInfo?.pendingOpenBankingOutcome]);

  let content = <></>;

  if (!personalDetailsResponse?.personalDetails?.customer)
    content = (
      <Container disableGutters sx={{ paddingX: '1.6rem' }}>
        <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
        <Typography
          variant="h1"
          fontSize={{ xs: '2.4rem', sm: '3.2rem' }}
          fontWeight={400}
          lineHeight={'3.2rem'}
          letterSpacing={{ xs: '-0.072rem', sm: '-0.05rem' }}
          paddingBottom={'2rem'}
        >
          Hello, welcome to Wonga!
        </Typography>
        <p>Let us show you around for you to get to know us better. </p>
      </Container>
    );

  if (
    (!openLoanDetailsResponse?.hasOpenLoan && openLoanStatus !== OpenLoanStatusEnum.CounterofferAvailable) ||
    openLoanDetailsResponse?.loanStateResponse?.applicationInfo?.isDeclined
  )
    content = (
      <>
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
          <Typography
            variant="h1"
            fontSize={{ md: '3.2rem', xs: '2.4rem' }}
            marginBottom={'1.2rem'}
            fontWeight={400}
            lineHeight={{ md: '3.5rem', xs: '2.5rem' }}
          >
            Hi {personalDetailsResponse?.personalDetails?.customer?.firstName}
          </Typography>
          <Typography>
            Your current trust rating means you can apply for up to <strong>R {creditLimit}</strong>{' '}
          </Typography>
        </Container>
        <br />
        <div className={styles['sliders-wrapper']}>
          <SliderWithFlap setCreditLimit={setCreditLimit} />
        </div>
        {/* <DebiCheck /> */}
        <HowItWorks />
      </>
    );

  if (showAccountSummaryContent) {
    if (openLoanStatus === OpenLoanStatusEnum.Unspecified) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
          <Timeout
            loanAmount={openLoanDetailsResponse.quotationData?.loanAmount ?? 0}
            term={openLoanDetailsResponse.quotationData?.term ?? 0}
            termInDays={openLoanDetailsResponse.quotationData?.termInDays ?? 0}
          />
        </Container>
      );
    }

    if (openLoanStatus === OpenLoanStatusEnum.PendingApplicationSigning) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
          <LoanAgreement
            setOpenLoanDetailsResponse={setOpenLoanDetailsResponse}
            openLoanResponse={openLoanDetailsResponse}
            refreshLoansSummary={refreshLoansSummary}
          />
        </Container>
      );
    }

    if (openLoanStatus === OpenLoanStatusEnum.PendingIvDocUpload) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
          <UploadDoc openLoanResponse={openLoanDetailsResponse} personalDetailsResponse={personalDetailsResponse} />
        </Container>
      );
    }
    if (openLoanStatus === OpenLoanStatusEnum.PendingIncomeAndBankVerification || openLoanStatus === OpenLoanStatusEnum.PendingMandateAcceptance) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
          <AccountTimeline
            loanStatus={openLoanStatus}
            openLoanResponse={openLoanDetailsResponse}
          />
        </Container>
      );
    }
    if (openLoanStatus === OpenLoanStatusEnum.Disbursing) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
          <Disbursing openLoanResponse={openLoanDetailsResponse} />
        </Container>
      );
    }
    if (openLoanStatus === OpenLoanStatusEnum.Active) {
      content = (
        <Container disableGutters>
          <Routes>
            <Route element={<Outlet />}>
              <Route
                index
                element={
                  <>
                    <ActiveLoan
                      openLoanResponse={openLoanDetailsResponse}
                      personalDetails={personalDetailsResponse}
                      repayStatus={repayStatusResponse}
                      debitOrders={debitOrderScheduleResponse?.debitOrderSchedules ?? []}
                    />
                  </>
                }
              />
              <Route
                path={RouterRoutes.repay}
                element={
                  <Suspense
                    fallback={
                      <Box display={'flex'} justifyContent={'center'} height={'80vh'} width={'100%'}>
                        <Loading />
                      </Box>
                    }
                  >
                    <Repay
                      openLoanResponse={openLoanDetailsResponse}
                      hasSettlementLetterResponse={hasSettlementLetter}
                      repayStatus={repayStatusResponse}
                    />
                  </Suspense>
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.eft}`}
                element={
                  <Eft openLoanResponse={openLoanDetailsResponse} />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.instantEft}`}
                element={<InstantEft openLoanResponse={openLoanDetailsResponse} />}
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.easyPay}`}
                element={
                  <EasyPay
                    openLoanResponse={openLoanDetailsResponse}
                    personalDetailsResponse={personalDetailsResponse}
                  />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.card}`}
                element={
                  <Card openLoanResponse={openLoanDetailsResponse} />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.capitecPay}`}
                element={
                  <CapitecPay openLoanResponse={openLoanDetailsResponse} />
                }
              />
            </Route>
          </Routes>
        </Container>
      );
    }
    if (openLoanStatus === OpenLoanStatusEnum.InArrears) {
      content = (
        <Container disableGutters>
          <Routes>
            <Route element={<Outlet />}>
              <Route
                index
                element={
                  <>
                    <Arrears
                      openLoanResponse={openLoanDetailsResponse}
                      personalDetails={personalDetailsResponse}
                      repayStatus={repayStatusResponse}
                    />
                  </>
                }
              />
              <Route
                path={RouterRoutes.repay}
                element={
                  <Repay openLoanResponse={openLoanDetailsResponse} hasSettlementLetterResponse={hasSettlementLetter} repayStatus={repayStatusResponse} />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.eft}`}
                element={
                  <Eft openLoanResponse={openLoanDetailsResponse} />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.instantEft}`}
                element={<InstantEft openLoanResponse={openLoanDetailsResponse} />}
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.easyPay}`}
                element={
                  <EasyPay
                    openLoanResponse={openLoanDetailsResponse}
                    personalDetailsResponse={personalDetailsResponse}
                  />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.card}`}
                element={
                  <Card openLoanResponse={openLoanDetailsResponse} />
                }
              />
              <Route
                path={`/${RouterRoutes.repay}/${RouterRoutes.capitecPay}`}
                element={
                  <CapitecPay openLoanResponse={openLoanDetailsResponse} />
                }
              />
            </Route>
          </Routes>
        </Container>
      );
    }
    if (
      openLoanStatus === OpenLoanStatusEnum.CounterofferAvailable &&
      !openLoanDetailsResponse.loanStateResponse?.applicationInfo?.isDeclined
    ) {
      content = (
        <Container disableGutters sx={{ paddingX: '1.6rem' }}>
          <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
          <CounterOffer
            setOpenLoanDetailsResponse={setOpenLoanDetailsResponse}
            openLoanResponse={openLoanDetailsResponse}
          />
        </Container>
      );
    }
  }

  if (openLoanDetailsResponse?.isDne)
    content = (
      <Container disableGutters sx={{ paddingX: '1.6rem' }}>
        <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>My loans</NavigateChevronLink>
        <DoNotEngage />
      </Container>
    );

  return (
    <>
      {isLoading ? <Loading text="Please wait while we retrieve your account details" /> :
        (
          <>
            <div className={styles.container}>
              <Container disableGutters sx={{ paddingX: '1.6rem' }}>
                {content}
                {showOffersText && (
                  <Typography variant="body1" fontWeight={300} marginBottom={'3.4rem'}>
                    Below are some possible ways to <b>save</b> on your monthly expenses, why not give it a try?
                  </Typography>
                )}
                <OptimonkContainer showContainer={showOptimonkContainer} />
              </Container>
            </div>
          </>
        )
      }
    </>
  );
};

export default AccountSummary;
