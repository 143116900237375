import { Container, Grid, Stack, Typography } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import {
  ISignApplicationRequest,
  SignApplicationRequest,
  GetLoanAgreementResponse,
  GetDirectDebitResponse,
} from '../../../../../clients/LoanClient';
import { Form, useForm } from '../../../../../components/Form/Form';
import FormCheckBox from '../../../../../components/Form/FormCheckbox';
import FormWrapper from '../../../../../components/Form/FormWrapper';
import useLoanClient from '../../../../../hooks/loan/Client';
import { termsAndConditionsSchema } from '../../../../../schemas/Schemas';
import { FULL_COLUMN_SIZE } from '../../../../../utils/GridColumnSizeDefinitions';
import AgreementTemplate from '../../../../../components/AgreementTemplate/AgreementTemplate';
import { useSnackBar } from '../../../../../contexts/SnackBarContext';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigateToDocumentUpload, navigateToIvOptionsPage } from '../../../../../utils/Helpers/NavigationHelper';
import { useTracking } from '../../../../../Tracking/TrackingContext';
import ButtonLoadingIndicator from '../../../../../components/Loading/ButtonLoadingIndicator';
import { RouterRoutes } from '../../../../../utils/RouterRoutes';
import Loading from '../../../../../components/Loading/Loading';
import { useAppSettings } from '../../../../../contexts/AppSettingsContext';
import { GetBankResponse } from '../../../../../clients/AccountClient';
import useAccountClient from '../../../../../hooks/account/Client';
import { CustomerIsOpenBankingEligible } from '../../../../../utils/CustomerOpenBankingEligibility';
import useCustomNavigate from '../../../../../hooks/Navigation';

const TermsAndConditionsPage: React.FC = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const [loanAgreementDocumentUrl, setLoanAgreementDocumentUrl] = useState<string>();
  const [directDebitDocumentUrl, setDirectDebitDocumentUrl] = useState<string>();
  const client = useLoanClient();
  const { displaySnackBar } = useSnackBar();
  const location = useLocation();
  //loanId gets passed through the state on navigate
  const loanId = location.state['loanId'] ?? '';
  const isConcurrentLoan = location.pathname.includes(RouterRoutes.concurrentJourney);
  const { isExistingCustomer } = useTracking();
  const appSettings = useAppSettings();
  const openBankingAutoApprovalEnabled = appSettings.incomeVerification.openBankingAutoApprovalEnabled;
  const accountClient = useAccountClient();
  const [disableCustomerOpenBanking, setDisableCustomerOpenBanking] = useState(false);
  const { navigateWithGclidParams } = useCustomNavigate();

  const redirectToIvOptionsPage = () => {
    navigateToIvOptionsPage(navigateWithGclidParams, loanId, isExistingCustomer, isConcurrentLoan, false);
  };

  const navigateToDocUploadPage = () => {
    navigateToDocumentUpload(navigateWithGclidParams, loanId, isExistingCustomer, isConcurrentLoan);
  };

  const form = useForm({
    criteriaMode: 'all',
    mode: 'onChange',
    schema: termsAndConditionsSchema,
  });

  const waitForLoanHistoryAutoIV = async () => {
    try {
      // requireDocuments will be 'true' when the loan can't be approved based on the customer's history
      // If the IV is auto-approved, requireDocuments will remain as 'false'
      // and the call will keep running for the remaining seconds until we reach 30 seconds.
      // At this point, mandatePending = true will be the next expected state
      const loanState = await client.waitApplication(
        loanId,
        30,
        false,
        true,
        true,
        false,
        false,
        false
      );

      if (loanState.applicationAdvice?.requireDocuments) {
        if (openBankingAutoApprovalEnabled && !disableCustomerOpenBanking) {
          redirectToIvOptionsPage();
        } else {
          navigateToDocUploadPage();
        }
      } else {
        navigate(`/${RouterRoutes.myLoan}`);
      }
    } catch (error) {
      throw "A server error occurred while calling waitApplication";
    }
  };

  const signTermsAndConditionsOnClick = async () => {
    setSubmitLoading(true);
    try {
      const signAppRequest: ISignApplicationRequest = {
        loanId: loanId,
      };
      await client.signApplication(new SignApplicationRequest(signAppRequest));
      await waitForLoanHistoryAutoIV();
    } catch (error) {
      displaySnackBar("We're currently experiencing a temporary technical issue. Please try again later.", 'error');
      console.error(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const getDirectDebit = useCallback(async () => {
    try {
      const response: GetDirectDebitResponse = await client.getDirectDebit(loanId);
      if (response.url !== null || undefined) {
        setDirectDebitDocumentUrl(response.url);
      }
    } catch (error) {
      console.error(error);
    }
  }, [loanId, client]);

  const getLoanAgreement = useCallback(async () => {
    try {
      const response: GetLoanAgreementResponse = await client.getLoanAgreement(loanId);
      if (response.url !== null || undefined) {
        setLoanAgreementDocumentUrl(response.url);
      }
    } catch (error) {
      console.error(error);
    }
  }, [loanId, client]);

  const setDisabledOpenBankingBanks = useCallback(async () => {
    try {
      const bankDetailsResponse: GetBankResponse = await accountClient.getBankAccount();
      const disabledOpenBankingBanks = appSettings.incomeVerification.disabledOpenBankingBanks.split(",");
      setDisableCustomerOpenBanking(CustomerIsOpenBankingEligible(bankDetailsResponse.bank?.bankName ?? "", disabledOpenBankingBanks));
    } catch (error) {
      console.error(error);
    }
  }, [accountClient, appSettings.incomeVerification.disabledOpenBankingBanks]);

  useEffect(() => {
    getLoanAgreement();
    getDirectDebit();
    setDisabledOpenBankingBanks();
  }, [getLoanAgreement, getDirectDebit, setDisabledOpenBankingBanks]);

  const processingContent = (
    <Stack
      sx={{
        width: "100%",
        flexGrow: 1,
        background: "transparent",
      }}>

      <Stack flexGrow={1} alignItems="center" paddingTop={4} paddingBottom={4}>
        <Typography variant="h2">Almost there!</Typography>
        <Loading />
        <Typography variant="body1" marginTop={2}>
          The final step may take up to <b>30 seconds</b>.
        </Typography>
        <Typography variant="body1" marginTop={2}>
          Please <b>do not close your browser.</b>
        </Typography>
      </Stack>
    </Stack>
  );

  const agreements =
    (
      <Container maxWidth="lg" sx={{ padding: 0 }}>
        <FormWrapper title="">
          <Form form={form} onSubmit={signTermsAndConditionsOnClick}>
            <Container>
              <Typography fontSize={'2.5em'} fontWeight={300}>
                You’ve passed our credit checks, please accept the loan conditions below.
              </Typography>
              <Stack spacing={1} marginTop={'2rem'}>
                <Typography variant="h2" fontWeight={300} marginBottom={'2rem'}>
                  Loan terms and conditions
                </Typography>
                <AgreementTemplate
                  url={/* eslint-disable  @typescript-eslint/no-non-null-assertion */ loanAgreementDocumentUrl!}
                />
              </Stack>
              <Stack spacing={1} marginTop={'4rem'}>
                <Typography variant="h2" fontWeight={300} marginBottom={'2rem'}>
                  Repayment terms and conditions
                </Typography>
                <AgreementTemplate
                  url={/* eslint-disable  @typescript-eslint/no-non-null-assertion */ directDebitDocumentUrl!}
                />
              </Stack>
            </Container>
            <Stack spacing={'2rem'} padding={'2rem 0rem'}>
              <FormCheckBox
                name="loanAgreementAccepted"
                label="By ticking this I accept the loan & selected insurance terms and conditions."
                control={form.control}
                required
                size="medium"
              />
              <FormCheckBox
                name="debitOrderAuthorisation"
                label="By ticking this, you’re confirming you’ve read and accepted the authorisation of the debit order."
                control={form.control}
                required
                size="medium"
              />
            </Stack>
            <Container sx={{ paddingBottom: '5rem' }}>
              {submitLoading && <ButtonLoadingIndicator />}
              {!submitLoading && (
                <Grid container alignItems="center">
                  <Grid
                    item
                    xs={FULL_COLUMN_SIZE}
                    display="flex"
                    justifyContent={{
                      xs: 'flex-end',
                    }}
                  >
                    <PrimaryButton type="submit">Next</PrimaryButton>
                  </Grid>
                </Grid>
              )}
            </Container>
          </Form>
        </FormWrapper>
      </Container>
    );

  return (
    <>
      {
        submitLoading ? processingContent : agreements
      }
    </>
  )
};

export default TermsAndConditionsPage;
