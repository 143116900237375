import { Avatar, Box, Link, Stack, Typography } from '@mui/material';
import { theme } from '../../theme/Theme';
import CloseIcon from '../../assets/img/Close.png';
import { useState } from 'react';
import { useAppSettings } from '../../contexts/AppSettingsContext';

interface DeathBenefitDescriptionPopupProps {
  open: boolean;
  handleClose: () => void;
}

const DeathBenefitDescriptionPopup: React.FunctionComponent<DeathBenefitDescriptionPopupProps> = ({
  open,
  handleClose,
}: DeathBenefitDescriptionPopupProps) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const { insurance } = useAppSettings();

  const handleOnClose = () => {
    setShowMore(false);
    handleClose();
  };

  return (
    <Box
      display={open ? 'block' : 'none'}
      position={'absolute'}
      sx={{
        top: { xs: showMore ? '9rem' : '23rem', md: showMore ? '9rem' : '22.2rem' },
        left: { xs: '1.2rem', md: 0 },
        right: { xs: 0, md: 0 },
        marginLeft: { xs: 'unset', md: 'auto' },
        marginRight: { xs: 'unset', md: 'auto' },
        width: { xs: 'calc(100vw - 2.3rem)', sm: '35.7rem' },
        borderRadius: '0.5rem',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        transition: 'all 0.25s ease-in-out',
        zIndex: '1',
      }}
    >
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexGrow={1}
        sx={{
          padding: '0.4rem 1.3rem',
          backgroundColor: theme.palette.secondary.main,
          borderTopLeftRadius: '0.5rem',
          borderTopRightRadius: '0.5rem',
        }}
      >
        <Typography
          fontSize={'1.8rem'}
          fontWeight={500}
          lineHeight={'4rem'}
          color={theme.palette.common.white}
          fontStyle={'normal'}
        >
          More about Death Benefit
        </Typography>
        <Avatar
          src={CloseIcon}
          alt={'Close Icon'}
          sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
          onClick={handleOnClose}
        />
      </Stack>

      <Box sx={{ backgroundColor: theme.palette.common.white, padding: '1.6rem', borderRadius: '0.5rem' }}>
        <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} fontStyle={'normal'}>
          Death Benefit provides immediate cover for your accidental or natural death from the date of your loan disbursement.
          There is no waiting period, no medical questions, and no need to even pay your first premium.
          You’ll be covered for triple the loan amount taken out today.
          The full terms and conditions will be provided once your loan has been paid out to you.
          You also get{' '}
          <Typography component={'span'} fontSize={'1.3rem'} fontWeight={600} lineHeight={'1.5rem'}>
            free Credit Life insurance{' '}
          </Typography>
          as an{' '}
          <Typography component={'span'} fontSize={'1.3rem'} fontWeight={600} lineHeight={'1.5rem'}>
            added benefit!
          </Typography>
        </Typography>
        <br />
        {!showMore ? (
          <Typography
            fontSize={'1.3rem'}
            fontWeight={400}
            lineHeight={'1.5rem'}
            fontStyle={'normal'}
            color={theme.palette.primary.main}
            onClick={() => setShowMore(true)}
            sx={{ cursor: 'pointer' }}
          >
            Read more
          </Typography>
        ) : (
          <>
            <Typography fontSize={'1.3rem'} fontWeight={300} lineHeight={'1.5rem'} fontStyle={'normal'}>
              Credit Life paid on your behalf by Wonga: Please note, we will insure your outstanding loan balance.
              This insurance covers the amount of the debt outstanding at the time of your death, retrenchment or temporary or permanent disability.
              Please note that you will not be responsible for paying the premium attributable to this Credit Life policy
              <br />
              <br />
              Wonga is a registered Juristic Representative of Groups Are Us Pty (Ltd) (An Authorized Financial Services
              Provider, Registration Number: 2020/553498/07, FSP No. 45735). The Death Benefit and Credit Life is
              underwritten by Sanlam Developing Markets Limited, an authorised financial services provider and licensed
              Insurer (Registration number 1911/003818/06, (FSP11230).
              <br /> <br />
              Should you have any questions, contact{' '}
              <Link href={`mailto:${insurance.insuranceContactEmail}`} sx={{ textDecoration: 'none' }}>
                {insurance.insuranceContactEmail}
              </Link>{' '}
              or phone{' '}
              <Link
                href={`tel:${insurance.insuranceContactNumber.replace(/\s/g, '')}`}
                sx={{ textDecoration: 'none' }}
              >
                {insurance.insuranceContactNumber}
              </Link>
              .
              <br /> <br />
              <Typography
                component={'span'}
                fontSize={'1.3rem'}
                fontWeight={300}
                lineHeight={'1.5rem'}
                fontStyle={'normal'}
                color={theme.palette.primary.main}
                onClick={() => setShowMore(false)}
                sx={{ cursor: 'pointer' }}
              >
                Read less
              </Typography>
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DeathBenefitDescriptionPopup;
