/* eslint  @typescript-eslint/no-non-null-assertion: 0 */
/* Turn rule off for file as used for setting values on page from response. If response is null then logic should remain. */
import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  EmploymentDetails,
  EmploymentIndustryEnum,
  EmploymentStatusEnum,
  IEmploymentDetails,
  IncomeFrequencyEnum,
  PositionEnum,
  UpdateEmploymentResponse,
  ICustomerDetails,
  CustomerDetails,
  ErrorResponse,
} from '../../../clients/AccountClient';
import { Form, useForm } from '../../../components/Form/Form';

import FormInputLabel from '../../../components/Form/FormInputLabel';
import FormInputWrapper from '../../../components/Form/FormInputWrapper';
import FormWrapper from '../../../components/Form/FormWrapper';
import Loading from '../../../components/Loading/Loading';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import useAccountClient from '../../../hooks/account/Client';
import { employmentDetailsSchema } from '../../../schemas/Schemas';
import { FULL_COLUMN_SIZE } from '../../../utils/GridColumnSizeDefinitions';
import {
  shouldShowEmployer,
  shouldShowWorkNumber,
  shouldShowTime,
  shouldShowUniversity,
  shouldShowArmedForces,
} from '../../../utils/Helpers/Employment';
import SelectFormController from '../../../components/MuiInput/FormControllers/SelectFormController';
import InputFormController from '../../../components/MuiInput/FormControllers/InputFormController';
import PrimaryButton from '../../../components/Buttons/PrimaryButton';
import RepaymentDate from '../../../components/Sliders/RepaymentDate';
import { theme } from '../../../theme/Theme';
import {
  employmentStatusMappings,
  incomeFrequencyMappings,
  industryMappings,
  positionMappings,
} from '../../../models/SelectOptions';
import PepFormField from './PepDefinition/PepFormField';
import { useWizardOutletContext } from '../Wizard';
import ButtonLoadingIndicator from '../../../components/Loading/ButtonLoadingIndicator';
import { useTracking } from '../../../Tracking/TrackingContext';
import DatePickerFormController from '../../../components/MuiInput/FormControllers/DatePickerFormController';

const EmploymentDetailsPage: React.FunctionComponent = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showPipOrPepDefinition, setShowPipOrPepDefinition] = useState(false);
  const [isPipOrPep, setIsPipOrPep] = useState(false);
  const { next } = useWizardOutletContext();
  const { displaySnackBar } = useSnackBar();
  const { TrackError } = useTracking();

  const [employmentDetails, setEmploymentDetails] = useState<IEmploymentDetails>();
  const [showEmployer, setShowEmployer] = useState(false);
  const [showWorkNumber, setShowWorkNumber] = useState(false);
  const [showTime, setShowTime] = useState(false);
  const [showUniversity, setShowUniversity] = useState(false);
  const [showArmedForces, setShowArmedForces] = useState(false);
  const [timeWithText, setTimeWithText] = useState('');
  const client = useAccountClient();
  const [showRepaymentDatePicker, SetShowRepaymentDatePicker] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true);
  const [customerData, setCustomerData] = useState<ICustomerDetails>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const employmentForm = useForm({
    criteriaMode: 'all',
    mode: 'onBlur',
    schema: employmentDetailsSchema,
  });
  const salaryDay = employmentForm.watch('salaryDay');
  const showNetIncomeHint = !employmentForm.getFieldState('netIncome').invalid;

  const fetchData = async () => {
    try {
      const employmentPromise = client.getEmployment();
      const customerPromise = client.getCustomer();
      const [customerResponse, EmploymentResponse] = await Promise.all([customerPromise, employmentPromise]);
      if (EmploymentResponse && customerResponse) {
        setIsPipOrPep(customerResponse?.customer?.isPip !== undefined ? customerResponse?.customer?.isPip : false);
        setCustomerData(customerResponse.customer);
        setEmploymentDetails(EmploymentResponse.employment);

        employmentForm.setValue(
          'employerName',
          EmploymentResponse?.employment?.employerName ? EmploymentResponse.employment!.employerName! : ''
        );
        employmentForm.setValue(
          'position',
          EmploymentResponse?.employment?.positionEnum
            ? EmploymentResponse.employment!.positionEnum!
            : PositionEnum.Unspecified
        );
        employmentForm.setValue(
          'industry',
          EmploymentResponse?.employment?.industryEnum
            ? EmploymentResponse.employment!.industryEnum!
            : EmploymentIndustryEnum.Unspecified
        );
        employmentForm.setValue(
          'incomeFrequency',
          EmploymentResponse?.employment?.incomeFrequencyEnum
            ? EmploymentResponse.employment!.incomeFrequencyEnum
            : IncomeFrequencyEnum.Unspecified
        );
        employmentForm.setValue(
          'salaryDay',
          // If a day was picked from Sliders, use that date even though the user has a salary date from a previous journey in the backend response
          // if a day was not picked from Sliders (for STLs), use day from previous journey (from database)
          // In case the neither condition is met, the user has to select a new salary day.
          getSalaryDayFromLocalStorage().trim() !== ''
            ? getSalaryDayFromLocalStorage()
            : EmploymentResponse?.employment?.salaryDay
              ? EmploymentResponse.employment!.salaryDay!.toString()
              : ''
        );
        employmentForm.setValue(
          'employmentStatus',
          EmploymentResponse?.employment?.employmentStatusEnum
            ? EmploymentResponse.employment!.employmentStatusEnum
            : EmploymentStatusEnum.Unspecified
        );
        employmentForm.setValue(
          'grossIncome',
          EmploymentResponse?.employment?.grossIncome ? EmploymentResponse.employment!.grossIncome!.toString() : ''
        );
        employmentForm.setValue(
          'netIncome',
          EmploymentResponse?.employment?.netIncome ? EmploymentResponse.employment!.netIncome!.toString() : ''
        );
        employmentForm.setValue(
          'workPhone',
          EmploymentResponse?.employment?.workPhone ? EmploymentResponse.employment!.workPhone! : ''
        );
        employmentForm.setValue(
          'employedOn',
          EmploymentResponse?.employment?.employedOn ? EmploymentResponse.employment!.employedOn! : undefined
        );

        employmentForm.setValue('university', '');
        employmentForm.setValue('division', '');
        setIsFetchingData(false);
      }
    } catch (error) {
      displaySnackBar('We’re currently experiencing a temporary technical issue. Please try again later.', 'error');
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (salaryDay) {
      const applyValuesString = localStorage.getItem('applyValues');
      const applyValues = JSON.parse(applyValuesString ?? '');
      localStorage.setItem('applyValues', JSON.stringify({ ...applyValues, salaryDay }));
    }
  }, [salaryDay]);

  const currentEmploymentStatus = employmentForm.watch('employmentStatus');

  const determineFieldsToDisplay = useCallback(() => {
    setShowEmployer(() => {
      return shouldShowEmployer(currentEmploymentStatus);
    });

    setShowWorkNumber(() => {
      return shouldShowWorkNumber(showEmployer, currentEmploymentStatus);
    });

    setShowTime(() => {
      return shouldShowTime(showEmployer, currentEmploymentStatus);
    });

    setShowUniversity(() => {
      return shouldShowUniversity(currentEmploymentStatus);
    });

    setShowArmedForces(() => {
      return shouldShowArmedForces(currentEmploymentStatus);
    });
  }, [currentEmploymentStatus, showEmployer]);

  const determineTimeWithText = useCallback(() => {
    if (showEmployer) {
      return "Start date with employer";
    }
    if (showUniversity) {
      return "Start date with university";
    }
    if (showArmedForces) {
      return "Start date with service";
    }
    return "";
  }, [showEmployer, showUniversity, showArmedForces]);

  useEffect(() => {
    if (currentEmploymentStatus) {
      determineFieldsToDisplay();
      setTimeWithText(() => determineTimeWithText());
    }
  }, [currentEmploymentStatus, determineFieldsToDisplay, determineTimeWithText]);

  const getSalaryDayFromLocalStorage = (): string => {
    const applyValues = localStorage.getItem('applyValues');
    if (applyValues) {
      const salaryDay = JSON.parse(applyValues)['salaryDay'];
      return `${salaryDay == 0 ? '' : salaryDay}`;
    }
    return '';
  };

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* This should be revisited, employedOn is part of IEmploymentDetails but is explicitly ignored. */
  const updateEmployment = async (data: any) => {
    setSubmitLoading(true);
    const employ: IEmploymentDetails = {
      ...employmentDetails,
      employerName: data.employerName.trim(),
      employmentStatusEnum: data.employmentStatus,
      workPhone: data.workPhone,
      industryEnum: data.industry,
      positionEnum: data.position,
      incomeFrequencyEnum: data.incomeFrequency,
      salaryDay: data.salaryDay,
      grossIncome: data.grossIncome,
      netIncome: data.netIncome,
      employedOn: data.employedOn ?? new Date('1/1/0001'),
    };

    const cus: ICustomerDetails = {
      ...customerData,
      isPip: isPipOrPep,
    };

    try {
      const customer = await client.updateCustomer(new CustomerDetails(cus));
      const employment: UpdateEmploymentResponse = await client.updateEmployment(new EmploymentDetails(employ));

      if (employment.employmentDetailsUpdated && customer.customerUpdated) {
        console.log('Successfully updated employment details');
        displaySnackBar('Employment Details saved', 'success');
        next();
      }
    } catch (error) {
      if (error instanceof ErrorResponse && error.errorCode === 400) {
        TrackError(error, error.message);
        displaySnackBar(error.message ?? "An validation error has occurred", 'error');
      } else {
        displaySnackBar('Oops, an error has occurred please try again', 'error');
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  if (isFetchingData) return <Loading text="Please wait while we retrieve your details." />;

  const onSalaryDayPick = (day: number) => {
    employmentForm.setValue('salaryDay', day.toString());
    employmentForm.trigger('salaryDay');
    SetShowRepaymentDatePicker(false);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const optInValue = event.target.value === 'true';
    setIsPipOrPep(optInValue);
    setShowPipOrPepDefinition(optInValue);
  };

  return (
    <FormWrapper title="Employment details">
      <Form form={employmentForm} onSubmit={updateEmployment}>
        <Grid container style={{ position: 'relative' }}>
          <FormInputLabel>Employment Status</FormInputLabel>
          <FormInputWrapper>
            <SelectFormController
              name="employmentStatus"
              label="Employment Status"
              menuItems={employmentStatusMappings}
              register={employmentForm.register}
              control={employmentForm.control}
            />
          </FormInputWrapper>
          {showEmployer && (
            <>
              <FormInputLabel>Employer Name</FormInputLabel>
              <FormInputWrapper>
                <InputFormController
                  name="employerName"
                  label="Employer Name"
                  placeholder="Employer Name"
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}

          <FormInputLabel>Gross monthly income (before tax)</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="grossIncome"
              label="Gross Income"
              placeholder=""
              register={employmentForm.register}
              control={employmentForm.control}
              currencyField
            />
          </FormInputWrapper>

          <FormInputLabel>Nett monthly income (after tax)</FormInputLabel>
          <FormInputWrapper>
            <InputFormController
              name="netIncome"
              label="Net Income"
              placeholder=""
              register={employmentForm.register}
              control={employmentForm.control}
              currencyField
            />
            {showNetIncomeHint && (
              <Box display="flex" justifyContent="left" height={'1rem'}>
                <Typography
                  justifyContent="left"
                  marginTop={'-1.1rem'}
                  sx={{
                    fontWeight: 300,
                    fontSize: '1.5rem',
                    fontStyle: 'italic',
                    lineHeight: '2rem',
                    color: '#757575',
                  }}
                >
                  *Your salary amount <span style={{ fontWeight: 400 }}>paid into your bank account</span>
                </Typography>
              </Box>
            )}
          </FormInputWrapper>
          {showArmedForces && (
            <>
              <FormInputLabel>Division</FormInputLabel>
              <FormInputWrapper>
                <InputFormController
                  name="division"
                  label=""
                  placeholder="Division"
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}
          {showUniversity && (
            <>
              <FormInputLabel>University</FormInputLabel>
              <FormInputWrapper>
                <InputFormController
                  name="university"
                  label="University"
                  placeholder="University"
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}
          {showEmployer && (
            <>
              <FormInputLabel>Employer Industry</FormInputLabel>
              <FormInputWrapper>
                <SelectFormController
                  name="industry"
                  label="Employer Industry"
                  menuItems={industryMappings}
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
              <FormInputLabel>Position</FormInputLabel>
              <FormInputWrapper>
                <SelectFormController
                  name="position"
                  label="Position"
                  menuItems={positionMappings}
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}
          {showTime && (
            <>
              <FormInputLabel>{timeWithText}</FormInputLabel>
              <FormInputWrapper>
                <DatePickerFormController name="employedOn" register={employmentForm.register} control={employmentForm.control} format={'MM/yyyy'} views={['year', 'month']} />
              </FormInputWrapper>
            </>
          )}

          {showWorkNumber && (
            <>
              <FormInputLabel>Work contact number</FormInputLabel>
              <FormInputWrapper>
                <InputFormController
                  name="workPhone"
                  label="Work Phone"
                  placeholder="(include area code)"
                  register={employmentForm.register}
                  control={employmentForm.control}
                />
              </FormInputWrapper>
            </>
          )}

          <FormInputLabel>Frequency of income</FormInputLabel>
          <FormInputWrapper>
            <SelectFormController
              name="incomeFrequency"
              label="Income Frequency"
              menuItems={incomeFrequencyMappings}
              register={employmentForm.register}
              control={employmentForm.control}
            />
          </FormInputWrapper>
          {showRepaymentDatePicker && (
            <Container
              style={{
                position: 'absolute',
                bottom: '-70px',
                left: isMobile ? '10px' : '385px',
              }}
            >
              <RepaymentDate
                SetShowRepaymentDatePicker={SetShowRepaymentDatePicker}
                displayRepaymentDate={showRepaymentDatePicker}
                onSalaryDayPick={onSalaryDayPick}
              />
            </Container>
          )}

          <FormInputLabel>Salary Day</FormInputLabel>
          <FormInputWrapper>
            <Box onClick={() => SetShowRepaymentDatePicker(!showRepaymentDatePicker)}>
              <InputFormController
                type="number"
                name="salaryDay"
                label="Salary Day"
                salaryDay={parseInt(salaryDay)}
                placeholder="Salary Day"
                register={employmentForm.register}
                control={employmentForm.control}
              />
            </Box>
          </FormInputWrapper>
          <PepFormField
            isPipOrPep={isPipOrPep}
            showPipOrPepDefinition={showPipOrPepDefinition}
            setShowPipOrPepDefinition={setShowPipOrPepDefinition}
            onChange={handleRadioChange}
          />
        </Grid>

        <Box sx={{ padding: '0 0 5rem 0' }}>
          {submitLoading && <ButtonLoadingIndicator />}
          {!submitLoading && (
            <Grid container alignItems="center">
              <Grid
                item
                xs={FULL_COLUMN_SIZE}
                display="flex"
                justifyContent={{
                  xs: 'flex-end',
                }}
              >
                <PrimaryButton type="submit">Next</PrimaryButton>
              </Grid>
            </Grid>
          )}
        </Box>
      </Form>
    </FormWrapper>
  );
};

export default EmploymentDetailsPage;
