/* eslint-disable  react/no-unescaped-entities */
import { Box, Container, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import searchBlue from '../../assets/img/webp/search.webp';
import trustBlue from '../../assets/img/webp/handshake.webp';
import walletBlue from '../../assets/img/webp/wallet.webp';
import { BackgroundBubbles } from '../../components/BackgroundBubbles/BackgroundBubbles';
import BlogSection from './BlogSection/BlogSection';
import styles from './Home.module.scss';
import HomeContainer from './HomeContainer/HomeContainer';
import IconCard from './IconCard/IconCard';
import { useAppSettings } from '../../contexts/AppSettingsContext';

const Home: React.FunctionComponent = () => {
  const { companyDetails } = useAppSettings();
  const blogContainerRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <BackgroundBubbles>
        <Outlet></Outlet>
        <Container sx={{ marginTop: { xs: 5, md: 15 } }}>
          <Stack sx={{ textAlign: 'center', gap: 3 }}>
            <Typography variant="h1" fontWeight={400} fontSize={{ xs: '30px', md: '40px' }}>
              Flexible, short term loans that give you back control.
            </Typography>
            <Typography variant="h2" fontSize={{ xs: '20px' }}>
              Our{' '}
              <a className={styles['home-link']} href="/short-term-loan">
                short term loans
              </a>{' '}
              help people manage their cash flow. If you need a quick loan to tide you over for a short while,
              we&apos;re here for you. Our{' '}
              <a className={styles['home-link']} href="/personal-loans">
                personal loan
              </a>{' '}
              process is simple and easy to understand. To find out more, visit{' '}
              <a className={styles['home-link']} href="/how-it-works">
                how it works.
              </a>
            </Typography>
          </Stack>
        </Container>
      </BackgroundBubbles>
      <HomeContainer>
        <IconCard
          heading="We're flexible"
          body="You can save interest by repaying your short term loan early. If things go wrong, we're here to help you find a solution."
          img={walletBlue}
          alt={'Blue wallet'}
        />
        <IconCard
          heading="We're committed to you"
          body="We lend responsibly, treat our customers fairly, and help people make sense of their money."
          img={trustBlue}
          alt={'Trust handshake'}
        />
        <IconCard
          heading="We're transparent"
          body="There are no hidden charges. We make all our fees completely clear upfront before you apply."
          img={searchBlue}
          alt={'Blue search'}
          imgWidth="57px"
          imgHeight="88px"
        />
      </HomeContainer>

      <HomeContainer ref={blogContainerRef}>
        <BlogSection ref={blogContainerRef} />
      </HomeContainer>

      <div className={styles['background-wrapper']}>
        <Container
          maxWidth="md"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: { xs: 5, md: 10 },
            marginBottom: { xs: 5, md: 8 },
          }}
        >
          <Stack sx={{ gap: 3 }}>
            <Typography variant="h2" textAlign={'center'} fontWeight={400} fontSize={{ xs: '30px', md: '40px' }}>
              We're here to help:
            </Typography>
            <Typography variant="h3" textAlign={'left'} fontSize={{ xs: '16px', md: '24px' }}>
              View your latest loan application status, balances, statements, and more!
              <Typography variant="body1" textAlign={'left'} fontSize={{ xs: '16px', md: '24px' }} marginTop={2}>
                Simply
                <a className={styles['home-link-cell']} href="/login">
                  {' login '}
                </a>
                to your account to access a variety of self-service options, including:
              </Typography>
            </Typography>
            <Box component="ul" sx={{ listStyleType: 'disc', marginLeft: 4, fontSize: { xs: '16px', md: '24px' } }}>
              <li>Latest loan application status</li>
              <li>Current loan balance</li>
              <li>Account statements </li>
              <li>Paid-up letters</li>
            </Box>
            <Typography
              variant="body1"
              textAlign={'left'}
              fontSize={{ xs: '16px', md: '24px' }}
              style={{ whiteSpace: 'pre-line' }}
            >
              Need help with something else? For issues like password reset or settlement letters, you can:
            </Typography>

            <Box component="ul" sx={{ listStyleType: 'disc', marginLeft: 4, fontSize: { xs: '16px', md: '24px' } }}>
              <li>
                Call us{' '}
                <a
                  className={styles['home-link-cell']}
                  href={`tel:${companyDetails.CompanyContactNumber.replace(/\s/g, '')}`}
                >
                  {' '}
                  {companyDetails.CompanyContactNumber.replace(/\s/g, '-')}
                </a>
              </li>
              <li>
                Visit our
                <a className={styles['home-link-cell']} href="/help">
                  {' '}
                  {' FAQ '}
                </a>
                for answers to common questions.
              </li>
              <li>
                Email us at{' '}
                <a className={styles['home-link-cell']} href={`mailto:${companyDetails.companyContactEmail}`}>
                  {' '}
                  {`${companyDetails.companyContactEmail}`}
                </a>{' '}
              </li>
            </Box>
          </Stack>
        </Container>
      </div>
    </>
  );
};

export default Home;
