import { useCallback, useEffect, useState } from "react";
import { GetBankResponse } from "../../clients/AccountClient";
import { useSnackBar } from "../../contexts/SnackBarContext";
import useAccountClient from "./Client";

const useBankDetails = () => {
  const [isPending, setIsPending] = useState(true);
  const { displaySnackBar } = useSnackBar();
  const [bankDetails, setBankDetails] = useState<GetBankResponse | null>();
  const client = useAccountClient();

  const fetchData = useCallback(async () => {
    try {
      const response: GetBankResponse = await client.getBankAccount();
      if (response) {
        setBankDetails(response);
        setIsPending(false);
      }
    } catch (error) {
      displaySnackBar(
        "We're currently experiencing a temporary technical issue. Please try again later.",
        "error"
      );
      console.error(error);
    }
  }, [client, displaySnackBar]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { isPending, bankDetails };
};

export default useBankDetails;
