import { OpenLoanStatusEnum } from "../../utils/Helpers/OpenLoanStatusEnum";
import { ApplicationStatus } from "../Enums/ApplicationStatus";

export const getPage = (applicationStatus: ApplicationStatus): string => {
  switch (applicationStatus) {
    case ApplicationStatus.resume:
      return "/resume";
    case ApplicationStatus.processing:
      return "/processing";
    case ApplicationStatus.readyToSign:
      return "/apply-accept";
    case ApplicationStatus.pendingIv:
      return "/pending-iv";
    case ApplicationStatus.thanks:
      return "/accept";
    case ApplicationStatus.declined:
      return "/decline";
    case ApplicationStatus.counterOffer:
      return "/apply-counter-offer";
    case ApplicationStatus.processingCounterOffer:
      return "/processing-counter-offer";
    case ApplicationStatus.error:
      return "/error";
    case ApplicationStatus.invalidBankDetails:
      return "/invalidBankDetails";
    case ApplicationStatus.invalidIncome:
      return "/invalidIncome";
    case ApplicationStatus.timeout:
      return "/timeout";
    default:
      return applicationStatus.toString();
  }
};

export const appendMember = (track: string, isExistingCustomer: boolean, isConcurrentLoan: boolean): string => {
  if (isExistingCustomer && !isConcurrentLoan) {
    return track + "-member";
  }
  else if (isExistingCustomer && isConcurrentLoan) {
    return track + "-plus"
  }
  return track;
};

export const getPageKeyBasedOnOpenLoanStatus = (openLoanStatus: OpenLoanStatusEnum): string => {
  switch (openLoanStatus) {
    case OpenLoanStatusEnum.Unspecified:
      return "timeout";
    case OpenLoanStatusEnum.PendingApplicationSigning:
      return "pending-loan-agreement";
    case OpenLoanStatusEnum.PendingIvDocUpload:
      return "pending-document-upload";
    case OpenLoanStatusEnum.PendingIncomeAndBankVerification:
      return "pending-iv";
    case OpenLoanStatusEnum.PendingMandateAcceptance:
      return "pending-mandate-accept";
    case OpenLoanStatusEnum.Disbursing:
      return "cashout";
    case OpenLoanStatusEnum.CounterofferAvailable:
      return "pending-counter-offer";
    default:
      return '';
  }
};
