import { Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useMyLoanContext } from "../MyLoanContext";
import OpenLoanSummary from "./OpenLoanSummary";
import SliderWithFlap from "../../../components/Sliders/slider-with-flap/slider-with-flap";
import HowItWorks from "../AccountSummary/HowItWorks/HowItWorks";
import { useEffect, useState } from "react";
import DoNotEngage from "../AccountSummary/DoNotEngage/DoNotEngage";
import LiveLoans from "./LiveLoans";
import { useLocation, useNavigate } from "react-router-dom";
import { RouterRoutes } from "../../../utils/RouterRoutes";
import Loading from "../../../components/Loading/Loading";
import lightbulb from '../../../assets/img/svg/lightbulb-big.svg';
import { getFriendlyOpenLoanStatus, OpenLoanStatusEnum } from "../../../utils/Helpers/OpenLoanStatusEnum";
import HowToGetMoreModal from "../../../components/HowToGetMoreModal/HowToGetMoreModal";

const Landing: React.FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { personalDetailsResponse, loansSummaryResponse } = useMyLoanContext();
    const [creditLimit, setCreditLimit] = useState(5000);
    const showSliders = !loansSummaryResponse.hasOpenLoanOrApplication && getFriendlyOpenLoanStatus(loansSummaryResponse.openApplication?.openLoanStatus) !== OpenLoanStatusEnum.CounterofferAvailable;
    const [showContent, setShowContent] = useState(false);
    const [showHowToGetMore, setShowHowToGetMore] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const howToGetMoreHandler = () => {
      setShowHowToGetMore(!showHowToGetMore);
    };

    // Auto redirect customer to their account summary screen
    // if they come from login and have an open application but no live loans
    useEffect(() => {
        if (!loansSummaryResponse.isDne && !showSliders && loansSummaryResponse.hasOpenLoanOrApplication) {
            const liveLoans = loansSummaryResponse.loans?.filter(x => getFriendlyOpenLoanStatus(x.openLoanStatus) === OpenLoanStatusEnum.Active || getFriendlyOpenLoanStatus(x.openLoanStatus) === OpenLoanStatusEnum.InArrears);
            const fromLogin = (location.state && location.state.from && location.state.from === 'login') ?? false;

            if (liveLoans?.length === 0 && loansSummaryResponse.openApplication && fromLogin) {
                navigate(`/${RouterRoutes.myLoan}/${loansSummaryResponse.openApplication.loanId}`);
                return;
            }
        }

        setShowContent(true);
    }, [loansSummaryResponse, location, navigate, showSliders]);

    if (loansSummaryResponse.isDne && showContent) {
        return (
            <Container disableGutters sx={{ paddingX: '1.6rem' }}>
                <DoNotEngage />
            </Container>
        );
    }
    else if (showSliders && showContent) {
        return <Stack alignItems={'flex-start'} justifyContent={'flex-start'} marginLeft={{ lg: '0rem', xl: '3rem' }} width={'100%'}>
            <Container disableGutters sx={{ paddingX: '1.6rem' }}>
                <Typography
                    variant="h1"
                    fontSize={{ md: '3.2rem', xs: '2.4rem' }}
                    marginBottom={'1.2rem'}
                    fontWeight={400}
                    lineHeight={{ md: '3.5rem', xs: '2.5rem' }}
                >
                    Hi {personalDetailsResponse?.personalDetails?.customer?.firstName}
                </Typography>
                <Container  sx={{ backgroundColor: theme.palette.grey['200'], padding: '1.2rem', borderRadius: '1rem', position: "relative"}}>
                  <Stack justifyContent={isMobile ? 'flex-start' : 'center'} flexDirection={'row'}>
                    <img src={lightbulb} alt="Lightbulb Icon" style={{ marginRight:'8px', marginTop:isMobile ? '10px' : '0' , height:'20px', width:'15px'}}/>
                    <Stack alignItems={'flex-start'} justifyContent={'flex-start'} flexDirection={isMobile ? 'column' : 'row' }>
                      <Typography
                      >
                        You can apply for up to <strong>R {creditLimit}</strong>
                      </Typography>
                      <Typography
                        fontSize={{ xs: '1.5rem' }}
                        color={'#008FD5'}
                        style={ {cursor: 'pointer', paddingLeft: isMobile ? "0" : "10px"}}
                        onClick={howToGetMoreHandler}
                        >
                        Discover how to get more
                      </Typography>
                      {showHowToGetMore ? (
                        <HowToGetMoreModal
                            showModal={showHowToGetMore}
                            onClose={howToGetMoreHandler}
                        />
                      ) : null}
                    </Stack>
                  </Stack>
                </Container>
            </Container>
            <br />
            <SliderWithFlap setCreditLimit={setCreditLimit} />
            <HowItWorks />
        </Stack>
    }

    return (
        <>
            {showContent ?
                (
                    <Container disableGutters sx={{ paddingX: '1.6rem', marginBottom: { xs: '4rem', md: '7.4rem' } }}>
                        <Typography variant="h1" fontSize={{ md: '3.2rem', xs: '2.4rem' }} marginBottom={'1.6rem'} fontWeight={400} lineHeight={{ md: '3.5rem', xs: '2.5rem' }}>
                            Hi {personalDetailsResponse?.personalDetails?.customer?.firstName}
                        </Typography>
                        <OpenLoanSummary loansSummaryResponse={loansSummaryResponse} personalDetails={personalDetailsResponse?.personalDetails} />
                        <LiveLoans loans={loansSummaryResponse?.loans ?? []} />
                    </Container>
                ) : <Loading text="Please wait while we retrieve your account details" />
            }
        </>
    );
}

export default Landing;
