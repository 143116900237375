import { Box, Typography, useMediaQuery } from '@mui/material';
import React, { Suspense, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import InformationImage from '../../../assets/img/webp/information.webp';
import { RouterRoutes } from '../../../utils/RouterRoutes';
import Loading from '../../Loading/Loading';
import FlapContainer from '../Flap-Container/Flap-container';
import Flap from '../Flap/Flap';
import styles from '../slider-with-flap/slider-with-flap.module.scss';
import { useAuthentication } from '../../../contexts/AuthContext';
const Sliders = React.lazy(() => import('../Sliders'));

interface SliderWithFlapProps {
  updateLoanValues?: (value: boolean) => void;
  setCreditLimit?: (value: number) => void;
}

const SliderWithFlap: React.FC<SliderWithFlapProps> = ({ updateLoanValues, setCreditLimit }: SliderWithFlapProps) => {
  const isMobile = useMediaQuery('(max-width:800px)');
  const location = useLocation();
  const { isAuthenticated } = useAuthentication();
  const onHomePage = location.pathname === '/';
  const onMyLoanPage = location.pathname.includes(RouterRoutes.myLoan);
  const isInJourney =
    location.pathname.includes(RouterRoutes.newJourney) || location.pathname.includes(RouterRoutes.existingJourney);

  const showFlapTop = onHomePage || isInJourney || (onMyLoanPage && !isMobile);

  // Resets the journey step when a user abandons a journey
  const resetCurrentJourneyStepIfSet = useCallback(() => {
    const storedWizardString = localStorage.getItem('wizard');

    if (storedWizardString) {
      const wizardObj = { currentStep: 0 };
      localStorage.setItem('wizard', JSON.stringify(wizardObj));
    }
  }, []);

  const onChangeUpdateLoanValues = useCallback(() => {
    if (updateLoanValues) {
      updateLoanValues(true);
    }
  }, [updateLoanValues]);

  useEffect(() => {
    resetCurrentJourneyStepIfSet();
  }, [resetCurrentJourneyStepIfSet]);

  const flapContainer = (
    <div className={styles['blue-content-flap']}>
      <FlapContainer theme="dark" dropdownText="How to apply" transparent="false">
        <Flap theme="darkBlue" title="What you can get" className={styles['list-container']}>
          <p className={styles['blue-content-text']}>
            New customers can apply for up to <b>R5000</b> with 3 months to repay. Existing customers can apply for up
            to <b>R8000</b> with up to 6 months to repay.
          </p>
        </Flap>
        <Flap title="What you'll need:">
          <div className={styles['list-container']}>
            <div className={styles['icon-mobile-iphone']}></div>
            <div className={styles['list-content']}> A cellphone number</div>
          </div>
          <div className={styles['list-container']}>
            <div className={styles['icon-bank-identity']}></div>
            <div className={styles['list-content']} style={{ marginLeft: '5px' }}>
              {' '}
              SA ID number
            </div>
          </div>
          <div className={styles['list-container']}>
            <div className={styles['icon-bank-white']}></div>
            <div className={styles['list-content']}> Bank account details</div>
          </div>
          <div className={styles['list-container']}>
            <div className={styles['icon-checklist']}></div>
            <div className={styles['list-content']}> Most recent proof of income</div>
          </div>
        </Flap>
        <Flap theme="darkBlue" title="How to apply">
          <div className={styles['steps-list']}>
            <div className={styles['steps-list-step']}>
              <div className={styles['steps-list-step-number']}>1</div>
              <div className={styles['steps-list-step-description']}>Choose the amount you need</div>
            </div>
            <div className={styles['steps-list-step']}>
              <div className={styles['steps-list-step-number']}>2</div>
              <div className={styles['steps-list-step-description']}>Choose how long you will need to repay</div>
            </div>
            <div className={styles['steps-list-step']}>
              <div className={styles['steps-list-step-number']}>3</div>
              <div className={styles['steps-list-step-description']}>
                Click {`"Apply Now"`} and proceed to finalise your loan
              </div>
            </div>
          </div>
        </Flap>
      </FlapContainer>
    </div>
  );

  const mobileDisplay = (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        borderRadius={2}
        bgcolor={'#F5F5F5'}
        margin={'0px 16px'}
      >
        <Box display="flex" alignItems="center" marginTop={'10px'}>
          <img
            style={{ marginRight: '8px' }}
            src={InformationImage}
            alt="Information Image"
            width={'16px'}
            height={'16px'}
          />
          <Typography variant="h4">Had a Wonga loan before?</Typography>
        </Box>

        <Typography variant="body1" paddingTop={'10px'} paddingBottom={'12px'} fontWeight={300}>
          Please{' '}
          <a className={styles['home-link-cell']} href="/login">
            {' log in '}
          </a>
          to see your personalised loan sliders.
        </Typography>
      </Box>
    </>
  );

  return (
    <>
      <div className={styles['wonga-slider-container']}>
        <div className={styles['wonga-slider-with-flap']}>
          {!isMobile && showFlapTop && flapContainer}
          {isMobile && !isAuthenticated && mobileDisplay}
          <div className={styles['calculator-panel']}>
            <Suspense
              fallback={
                <Box display={'flex'} justifyContent={'center'} height={{ xs: '46rem', sm: 'unset' }} width={'100%'}>
                  <Loading />
                </Box>
              }
            >
              <Sliders updateLoanValues={onChangeUpdateLoanValues} setCreditLimit={setCreditLimit} />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
};

export default SliderWithFlap;
