import { Box } from '@mui/material';
import React, { ChangeEvent, useRef } from 'react';
import PrimaryButton from '../../Buttons/PrimaryButton';
import SecondaryButton from '../../Buttons/SecondaryButton';
import TextButton from '../../Buttons/TextButton';

interface FileUploadButtonProps {
    title: string;
    isDisabled?: boolean;
    buttonType: "primary" | "secondary" | "text";
    handleFileUpload: (file: File) => Promise<void>;
    width?: string | number;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = (props) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const resetInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files) {
            const fileList = e.target.files;
            const files = Array.from(fileList);
            await props.handleFileUpload(files[0]);
            resetInput();
        }
    };

    const renderButton = () => {
        if (props.buttonType === "secondary") {
            return (
                <SecondaryButton onClick={handleButtonClick} disabled={props.isDisabled}>
                    {props.title}
                </SecondaryButton>
            );
        } else if (props.buttonType === "text") {
            return (
                <TextButton onClick={handleButtonClick} disabled={props.isDisabled} removePadding>
                    {props.title}
                </TextButton>
            );
        } else {
            return (
                <PrimaryButton onClick={handleButtonClick} disabled={props.isDisabled}>
                    {props.title}
                </PrimaryButton>
            );
        }
    };

    return (
        <Box width={props.width ?? "100%"}>
            <input
                ref={fileInputRef}
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            {renderButton()}
        </Box>
    );
}

export default FileUploadButton;