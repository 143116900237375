export interface ApplyValueProps {
    productId: string,
    amount: number,
    term: number,
    salaryDay: number,
    applicationDate: Date,
    includeInsurance: boolean,
}

export const setApplyValues = (applyValues: ApplyValueProps) => {
    localStorage.setItem(
        'applyValues',
        JSON.stringify({
            amount: applyValues.amount,
            term: applyValues.term,
            salaryDay: applyValues.salaryDay,
            applicationDate: applyValues.applicationDate,
            includeInsurance: applyValues.includeInsurance,
            productId: applyValues.productId,
        })
    );
};