import { Avatar, Box, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import NavigateChevronLink from "../../../../components/NavigateChevronLink/NavigateChevronLink";
import { RouterRoutes } from "../../../../utils/RouterRoutes";
import { formatMoney, formatMoneyWithoutRounding, formatRepaymentDay } from "../../../../utils/Helpers/SliderHelperFunctions";
import InfoIcon from '../../../../assets/img/info-icon.png';
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import useLoanClient from "../../../../hooks/loan/Client";
import { GetInstallmentLoanQuotationResponse } from "../../../../clients/LoanClient";
import { useTracking } from "../../../../Tracking/TrackingContext";
import { useSnackBar } from "../../../../contexts/SnackBarContext";
import Loading from "../../../../components/Loading/Loading";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import TextButton from "../../../../components/Buttons/TextButton";
import SliderWithFlap from "../../../../components/Sliders/slider-with-flap/slider-with-flap";
import chevronLeft from '../../../../assets/img/svg/Chevron.svg';
import QuotationFeeTooltip from "./QuotationFeeTooltip";
import { ProductIdEnum } from "../../../../components/Sliders/constants";
import { useWizardOutletContext } from "../../Wizard";


const ConcurrentLoanOffer: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const loanClient = useLoanClient();
    const Tracking = useTracking();
    const { displaySnackBar } = useSnackBar();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const greyBorder = `1px solid ${theme.palette.grey[200]}`;
    const loanAmount = location.state?.loanAmount;
    const term = location.state?.term;
    const salaryDay = location.state?.salaryDay;

    const [showSliders, setShowSliders] = useState(false);
    const [showQuotationFeeTooltip, setShowQuotationFeeTooltip] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [quotationResponse, setQuotationResponse] = useState<GetInstallmentLoanQuotationResponse | undefined>();
    const { next } = useWizardOutletContext();


    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const getInstallmentLoanQuotationResponse = await loanClient.getInstallmentLoanQuotation(
                ProductIdEnum.instalmentLoan,
                loanAmount,
                term,
                salaryDay,
                new Date(),
                true,
                false,
                false
            );

            setQuotationResponse(getInstallmentLoanQuotationResponse);
            setIsLoading(false);
        } catch (error) {
            Tracking.TrackError(error, "Failed to fetch Concurrent Loan offer quotation");
            displaySnackBar("An error occured, please try again later.", "error");
        }
    }, [Tracking, displaySnackBar, loanAmount, loanClient, salaryDay, term]);

    const getInterestAndFees = () => {
        if (quotationResponse && quotationResponse.totalServiceFees && quotationResponse.interestAmount)
            return quotationResponse.totalServiceFees + quotationResponse.interestAmount;

        return 0;
    }

    const handleNext = () => {
        localStorage.setItem(
            'applyValues',
            JSON.stringify({
                amount: loanAmount,
                term: term,
                salaryDay: 0,
                applicationDate: quotationResponse?.applicationDate,
                includeInsurance: false,
                productId: quotationResponse?.productId,
            })
        );

        Tracking.TrackAction("concurrent_offer_clicked_next", { value: true })

        next();
    }

    useEffect(() => {
        if (!location.state || !location.state['loanAmount'] || !location.state['term'] || !location.state['salaryDay'])
            navigate(`/${RouterRoutes.myLoan}`);
        else
            fetchData();

    }, [location, navigate, fetchData]);

    if (showSliders) {
        return (
            <Container disableGutters>
                <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    marginBottom={{ xs: '0rem', md: '2.4rem' }}
                    paddingLeft={{ xs: '1.6rem', md: '15rem' }}
                    onClick={() => setShowSliders(false)}
                    sx={{ cursor: 'pointer' }}>
                    <Avatar
                        src={chevronLeft}
                        alt="Back button icon"
                        sx={{
                            width: '1.4rem',
                            height: '1.4rem',
                            marginRight: '0.4rem',
                        }}
                    />
                    <Typography
                        display={'inline'}
                        fontSize={'1.5rem'}
                        fontWeight={'300'}
                        lineHeight={{ xs: '2.2rem' }}
                        letterSpacing={{ xs: '-.0.04rem' }}
                    >
                        Back
                    </Typography>
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'center'} marginBottom={{ xs: '4rem', md: '7.5rem' }}>
                    <SliderWithFlap />
                </Stack>
            </Container>
        );
    }

    const handleChangeApplicationDetails = () => {
        Tracking.TrackAction("concurrent_offer_clicked_change_application_details", { value: true })
        setShowSliders(true);
    }

    return (
        <Container sx={{ marginBottom: { xs: '3.6rem', md: '7.4rem' }, marginTop: { xs: 0, md: theme.spacing(7) } }}>
            {isLoading && <Loading />}
            {!isLoading && quotationResponse &&
                (
                    <>
                        <NavigateChevronLink route={`/${RouterRoutes.myLoan}`}>Back</NavigateChevronLink>
                        <Typography
                            variant="body1"
                            fontSize={'3.2rem'}
                            fontWeight={500}
                            lineHeight={'2.2rem'}
                            letterSpacing={'-0.04em'}
                            textAlign={'center'}
                            marginTop={isMobile ? '2.4rem' : '2.8rem'}
                            color={theme.palette.primary.dark}
                        >
                            R {formatMoneyWithoutRounding(loanAmount ?? 0, 0)}
                        </Typography>
                        <Typography variant="body1" fontSize={'1.4rem'} fontWeight={400} lineHeight={'2.2rem'} textAlign={'center'} letterSpacing={'-0.4px'} marginTop={'1rem'}>
                            Application amount
                        </Typography>

                        <Box marginTop={'2rem'}>
                            <TableRow borderTop={greyBorder}>
                                <Stack direction={'row'} alignItems={'center'} display={'relative'}>
                                    Total fees & interest
                                    <img
                                        onClick={() => setShowQuotationFeeTooltip(true)}
                                        src={InfoIcon}
                                        width={'15px'}
                                        height={'15px'}
                                        style={{ marginLeft: '0.8rem', cursor: 'pointer' }}
                                        alt="infoIcon"
                                    />
                                    <QuotationFeeTooltip open={showQuotationFeeTooltip} handleClose={() => setShowQuotationFeeTooltip(false)} deathBenefitOptedIn={false} getQuotationResponse={quotationResponse} />
                                </Stack>
                                <Typography fontWeight={600}>R {formatMoney(getInterestAndFees())}</Typography>
                            </TableRow>
                            <TableRow borderTop={greyBorder}>
                                <div>
                                    Total to repay
                                </div>
                                <Typography fontWeight={600}>R {formatMoney(quotationResponse.totalRepayable ?? 0)}</Typography>
                            </TableRow>
                            <TableRow borderTop={greyBorder}>
                                <div>
                                    Term
                                </div>
                                <Typography fontWeight={600}>{`${term} months`}</Typography>
                            </TableRow>
                            <TableRow borderTop={greyBorder}>
                                <Stack direction={'column'}>
                                    Instalment date
                                    <Typography
                                        fontSize={{ xs: '1.2rem', md: '1.3rem' }}
                                        fontWeight={400}
                                        color={theme.palette.grey[500]}
                                        lineHeight={'2.2rem'}
                                        letterSpacing={'-0.4px'}
                                    >
                                        Subject to banking days
                                    </Typography>
                                </Stack>
                                <Typography fontWeight={600} alignSelf={'start'}>{formatRepaymentDay(salaryDay)} of every month</Typography>
                            </TableRow>
                        </Box>
                        <Stack
                            flexDirection={{ xs: 'column', md: 'row-reverse' }}
                            alignItems={'center'}
                            justifyContent={{ xs: 'center', md: 'space-between' }}
                            marginTop={{ xs: '4rem', md: '5.6rem' }}
                            gap={{ xs: '1.6rem', md: 0 }}
                        >
                            <PrimaryButton onClick={handleNext}>Next</PrimaryButton>
                            <TextButton removePadding onClick={handleChangeApplicationDetails}>Change application details</TextButton>
                        </Stack>
                    </>
                )}
        </Container>
    );
}

type TableRowProps = {
    children: React.ReactNode;
    borderTop?: string | undefined;
    borderBottom?: string | undefined;
}

const TableRow: React.FC<TableRowProps> = ({ children, borderTop, borderBottom }) => {
    return <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{
        width: '100%',
        borderTop: borderTop ?? 'none',
        borderBottom: borderBottom ?? 'none',
        fontSize: '1.5rem',
        fontWeight: '300',
        fontStyle: 'normal',
        paddingY: '1.4rem',
        paddingX: '1.6rem'
    }}>
        {children}
    </Stack>
}

export default ConcurrentLoanOffer;