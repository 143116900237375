import { useLocation, useNavigate } from "react-router-dom";
import { useTracking } from "../../../../Tracking/TrackingContext";
import { RouterRoutes } from "../../../../utils/RouterRoutes";
import { navigateToDocumentUpload } from "../../../../utils/Helpers/NavigationHelper";
import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import VerifiedIncomeImage from '../../../../assets/img/verified-income-image.png';
import { RedirectToTruId } from "./Utilities/OpenBankingRedirectUrlUtils";
import useLoanClient from "../../../../hooks/loan/Client";
import { useState } from "react";
import { OpenBankingRedirectUrlRequestErrorDialog } from "./OpenBankingRedirectUrlRequestErrorDialog";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";

export const CancelledOpenBanking = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const loanClient = useLoanClient();
  const navigate = useNavigate();
  const { isExistingCustomer } = useTracking();
  const location = useLocation();
  const loanId = location && location.state ? location.state["loanId"] ?? "" : "";
  const isConcurrentLoan = location.pathname.includes(RouterRoutes.concurrentJourney);
  const errorDialogOpened = location && location.state ? location.state["errorDialogOpened"] ?? false : false;
  const [openErrorDialogPopup, setOpenErrorDialogPopup] = useState(errorDialogOpened);
  const [isLoading, setIsLoading] = useState(false);

  const redirectToDocumentUpload = () => {
    navigateToDocumentUpload(navigate, loanId, isExistingCustomer, isConcurrentLoan);
  };

  const handleTruIdRedirect = async () => {
    await RedirectToTruId(loanClient, loanId, setOpenErrorDialogPopup, setIsLoading);
  };

  const handleErrorDialogPopupClose = () => {
    setOpenErrorDialogPopup(false);
  };

  return (
    <>
      <OpenBankingRedirectUrlRequestErrorDialog
        handleDialogClose={handleErrorDialogPopupClose}
        navigateToDocUploadPage={redirectToDocumentUpload}
        open={openErrorDialogPopup} />
      <Container sx={{ marginBottom: "4rem", padding: { md: "0 5rem", xs: "0 2rem" } }}>
        <Stack direction={isMobile ? "column" : "row"}>
          <Box textAlign={isMobile ? "center" : "left"}>
            <img src={VerifiedIncomeImage} alt="Verified Income Image" width={'140rem'} height={'140rem'} />
          </Box>
          <Stack direction={"column"} sx={{ marginTop: { md: '2.3rem' } }}>
            <Typography style={{ fontSize: "2.4rem" }}>
              We couldn’t successfully connect to your bank
            </Typography>
            <Typography style={{ paddingTop: '24px', fontSize:"1.5rem" }}>
              We could not connect to your bank. Please try again or alternatively upload your bank statements
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: { xs: '50px', md: '100px' } }} direction={isMobile ? "column" : "row-reverse"} justifyContent={"space-between"}>
          <Box paddingBottom={'20px'}>
            <PrimaryButton onClick={handleTruIdRedirect} disabled={isLoading}>
              <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
                Reconnect to your bank
              </Typography>
            </PrimaryButton>
          </Box>
          <SecondaryButton onClick={redirectToDocumentUpload} disabled={isLoading}>
            <Typography variant="body1" fontSize="1.5rem" fontWeight={300}>
              Upload documents
            </Typography>
          </SecondaryButton>
        </Stack>
      </Container>
    </>
  );

}
