import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Loading from '../../../components/Loading/Loading';
import {
  CanApplyResponse,
  ClientWatermark,
  DeathBenefitPolicy,
  GetInstallmentLoanQuotationResponse,
  GetShortTermLoanQuotationResponse,
  ILoanApplicationRequest,
  LoanApplicationRequest,
  LoanApplicationResponse,
  WaitApplicationResponse,
} from '../../../clients/LoanClient';
import useLoanClient from '../../../hooks/loan/Client';
import { AdBlockPopup } from '../../../components/AdBlockPopup/AdBlockPopup';
import { AppSettingsContext } from '../../../contexts/AppSettingsContext';
import { useSnackBar } from '../../../contexts/SnackBarContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { PostProcessingRoutes, RouterRoutes } from '../../../utils/RouterRoutes';
import { useTracking } from '../../../Tracking/TrackingContext';
import { ApplicationStatus } from '../../../Tracking/Enums/ApplicationStatus';
import { initialSliderConfig } from '../../../components/Sliders/constants';
import { wait } from '../../../utils/Helpers/SliderHelperFunctions';
import { determineJourneyPath, navigateToDocumentUpload, navigateToTermsAndConditions } from '../../../utils/Helpers/NavigationHelper';
import { CreditLifePlusBeneficiaryDetailsKey } from './VAS/CreditLifePlus/CreditLifePlusBeneficiary';
import useCustomNavigate from '../../../hooks/Navigation';
interface IQuotationParams {
  productId: string;
  loanAmount: number;
  term: number;
  salaryDay: number;
  applicationDate: Date;
  includeInsurance: boolean;
  includeDeathBenefit: boolean;
}

/* eslint-disable  @typescript-eslint/no-unused-vars*/
const Processing: React.FunctionComponent = () => {
  const loanClient = useLoanClient();
  const navigate = useNavigate();
  const location = useLocation();
  const appSettings = useContext(AppSettingsContext);
  const [openAdBlockPopup, setOpenAdBlockPopup] = React.useState(false);
  const { displaySnackBar } = useSnackBar();
  const [refreshCount, setRefreshCount] = useState(1);
  const { TrackQuotation, ApplicationStatusTrack, TrackError, isPersonalDetailsComplete } = useTracking();
  const { isExistingCustomer } = useTracking();
  const [waitApplicationResponse, setWaitApplicationResponse] = React.useState<WaitApplicationResponse>();
  const [processing, setProcessing] = useState<boolean>(true);
  const [loanId, setLoanId] = useState<string>();
  const isConcurrentLoan = location.pathname.includes(RouterRoutes.concurrentJourney);
  const { navigateWithGclidParams } = useCustomNavigate();

  const handleError = (error: unknown, trackProcessingError: boolean) => {
    TrackError(error, 'Processing error occured');
    if (trackProcessingError) ApplicationStatusTrack(ApplicationStatus.error, isConcurrentLoan);
    displaySnackBar('Oops! An unexpected error has occurred. Please try again later', 'error');
    navigate(`/${RouterRoutes.myLoan}`);
  };

  const getQuotationWithRetry = async (
    params: IQuotationParams,
    currentRetryAttempt = 1,
    maxRetries = 5
  ): Promise<GetShortTermLoanQuotationResponse | GetInstallmentLoanQuotationResponse | undefined> => {
    const { productId, loanAmount, term, salaryDay, applicationDate, includeInsurance, includeDeathBenefit } = params;
    const isInstallmentLoan = initialSliderConfig.installmentLoanConfig.productId === productId;
    //Note: saveQuotation flag is set to true only on processing to avoid duplicate records in quotation table
    try {
      if (isInstallmentLoan)
        return await loanClient.getInstallmentLoanQuotation(
          productId,
          loanAmount,
          term,
          salaryDay,
          applicationDate,
          includeInsurance,
          true,
          includeDeathBenefit
        );
      return await loanClient.getShortTermLoanQuotation(
        productId,
        loanAmount,
        term,
        salaryDay,
        applicationDate,
        includeInsurance,
        true,
        includeDeathBenefit
      );
    } catch (error) {
      if (currentRetryAttempt < maxRetries) {
        await wait(500);
        return await getQuotationWithRetry(params, currentRetryAttempt + 1, maxRetries);
      } else {
        throw `${isInstallmentLoan
          ? 'A server error occured while calling getInstallmentLoanQuotation'
          : 'A server error occured while calling getShortTermLoanQuotation'
        }`;
      }
    }
  };

  const waitApplicationWithRetry = async (
    loanId: string,
    currentRetryAttempt = 1,
    maxRetries = 5
  ): Promise<WaitApplicationResponse | undefined> => {
    try {
      return await loanClient.waitApplication(loanId, 30, true, false, false, false, true, false);
    } catch (error) {
      if (currentRetryAttempt < maxRetries) {
        await wait(500);
        return await waitApplicationWithRetry(loanId, currentRetryAttempt + 1, maxRetries);
      } else {
        throw 'A server error occured while calling waitApplication';
      }
    }
  };

  const canApplyWithRetry = async (
    productId: string,
    currentRetryAttempt = 1,
    maxRetries = 5
  ): Promise<CanApplyResponse | undefined> => {
    try {
      return await loanClient.canApply(productId);
    } catch (error) {
      if (currentRetryAttempt < maxRetries) {
        await wait(500);
        return await canApplyWithRetry(productId, currentRetryAttempt + 1, maxRetries);
      } else {
        throw 'A server error occured while calling canApply';
      }
    }
  };

  const applyWithRetry = async (
    request: LoanApplicationRequest,
    currentRetryAttempt = 1,
    maxRetries = 5
  ): Promise<LoanApplicationResponse> => {
    try {
      return await loanClient.apply(request);
    } catch (error) {
      if (currentRetryAttempt < maxRetries) {
        await wait(500);

        const canApplyResponse = await canApplyWithRetry(request.productId ?? '');

        if (!canApplyResponse?.canApply) throw 'Can apply is false';

        return await applyWithRetry(request, currentRetryAttempt + 1, maxRetries);
      } else {
        throw 'A server error occured while calling apply';
      }
    }
  };

  const startApplicationProcessingLoop = async (loanId: string) => {
    let response: WaitApplicationResponse | undefined;
    for (let i = 0; i < 10; i++) {
      response = await waitApplicationWithRetry(loanId);

      if (response?.success || response?.applicationInfo?.isDeclined) break;
    }
    return response;
  };

  const handleRefresh = () => {
    // Incrementing the refreshCount to trigger the canApply useCallback.
    // Another attempt to check blackbox data from localStorage
    setRefreshCount(refreshCount + 1);
  };

  const getApplyValues = () => {
    const applyValuesString = localStorage.getItem('applyValues');
    if (!applyValuesString) throw 'Apply values missing from local storage';
    return JSON.parse(applyValuesString);
  };

  const getBeneficiaryValues = () => {
    const beneficiaryValuesString = localStorage.getItem(CreditLifePlusBeneficiaryDetailsKey);
    if (!beneficiaryValuesString) return undefined;
    return JSON.parse(beneficiaryValuesString);
  };

  const getClientWaterMark = (): ClientWatermark => {
    const clientWatermark = new ClientWatermark();
    clientWatermark.blackBoxName = appSettings.blackBoxName;
    clientWatermark.blackBoxData = localStorage.getItem('blackbox') ?? undefined;
    return clientWatermark;
  };

  const handleAdBlockPopupClose = () => {
    setOpenAdBlockPopup(false);
    navigate(`/${RouterRoutes.myLoan}`);
  };

  const handleDecision = useCallback(() => {
    const isRequireSign = waitApplicationResponse?.applicationAdvice?.requireSign;
    const isRequireDocuments = waitApplicationResponse?.applicationAdvice?.requireDocuments;
    const isDeclined = waitApplicationResponse?.applicationInfo?.isDeclined;
    const success = waitApplicationResponse?.success;
    // Added !isDeclined to this because when the application is declined, success is returned as false
    const isTimedOut = !success && !isDeclined;
    const counterOfferAvailable = waitApplicationResponse?.applicationAdvice?.counterOffer;
    if (isRequireSign) {
      navigateToTermsAndConditions(navigateWithGclidParams, loanId ?? '', isExistingCustomer, isConcurrentLoan, true);
    } else if (isRequireDocuments) {
      navigateToDocumentUpload(navigateWithGclidParams, loanId ?? '', isExistingCustomer, isConcurrentLoan, true);
    } else if (isDeclined) {
      const route = determineJourneyPath(isExistingCustomer, isConcurrentLoan);
      navigateWithGclidParams(`/${route}/${PostProcessingRoutes.declined}`, { replace: true });
    } else if (counterOfferAvailable) {
      const route = determineJourneyPath(isExistingCustomer, isConcurrentLoan);
      navigateWithGclidParams(`/${route}/${PostProcessingRoutes.counterOffer}`, { replace: true });
    } else if (isTimedOut) {
      navigateWithGclidParams(`/${RouterRoutes.myLoan}/${loanId}`, { replace: true });
    }
  }, [isConcurrentLoan, isExistingCustomer, loanId, navigateWithGclidParams, waitApplicationResponse?.applicationAdvice?.counterOffer, waitApplicationResponse?.applicationAdvice?.requireDocuments, waitApplicationResponse?.applicationAdvice?.requireSign, waitApplicationResponse?.applicationInfo?.isDeclined, waitApplicationResponse?.success]);

  const processApplication = useCallback(async () => {
    try {
      const applyValues = getApplyValues();

      const productId = applyValues?.productId ?? '';

      const canApplyResponse = await canApplyWithRetry(productId);

      if (!canApplyResponse?.canApply) throw 'Can apply is false';

      const includeInsurance = applyValues?.includeInsurance ?? true;

      const quotationResponse = await getQuotationWithRetry({
        productId,
        loanAmount: applyValues.amount,
        term: applyValues.term,
        salaryDay: applyValues.salaryDay,
        applicationDate: new Date(applyValues.applicationDate),
        includeInsurance: includeInsurance,
        includeDeathBenefit: !includeInsurance,
      });

      const clientWatermark = getClientWaterMark();

      // Last Blackbox validation before application submission
      // If Blackbox data doesn't exist, wait a few seconds to get blackbox data in case the user interacts with this processing screen.
      // if all attempts fail, display Ad-blocker, which should redirect the user to MyLoan
      if (clientWatermark.blackBoxData == undefined) {
        if (refreshCount > 5) {
          // when refreshCount is 6, exit the useCallback so it doesn't try the 6th attempt
          setOpenAdBlockPopup(true);
          TrackError('Missing blackbox data on processing');
          return;
        }

        handleRefresh(); // This can actually be used as a timer to expect a user interacts with the page before the application submission
        console.log(`Attempt #${refreshCount} to set the Iovation IGLOO window object or/and get Blackbox data.`);

        return;
      }

      let deathBenefitPolicy = undefined;

      // Only include beneficiary details if death benefit opted-in and beneficiary details page enabled
      if (appSettings.insurance.deathBenefitIncludeBeneficiaryDetails && !includeInsurance) {
        const deathBenifitValues = getBeneficiaryValues();

        deathBenefitPolicy = new DeathBenefitPolicy({
          beneficiaryFirstName: deathBenifitValues['firstname'],
          beneficiaryLastName: deathBenifitValues['lastname'],
          beneficiaryContact: deathBenifitValues['cellphoneNumber'],
          beneficiaryRelationship: deathBenifitValues['beneficiaryRelationship'],
        });
      }

      const request: ILoanApplicationRequest = {
        productId: productId,
        quotationId: quotationResponse?.quotationId,
        clientWatermark: clientWatermark,
        deathBenefitPolicy: deathBenefitPolicy,
      };

      const loanApplicationResponse = await applyWithRetry(new LoanApplicationRequest(request));

      if (!loanApplicationResponse?.loanId) throw `Application status: ${loanApplicationResponse?.status}`;

      TrackQuotation(quotationResponse, loanApplicationResponse.loanId);

      setLoanId(loanApplicationResponse.loanId);

      const waitApplicationResponse = await startApplicationProcessingLoop(loanApplicationResponse.loanId);

      setWaitApplicationResponse(waitApplicationResponse);
      setProcessing(false);
    } catch (error) {
      handleError(error, true);
      console.log(error);
    } finally {
      localStorage.removeItem('wizard');
    }
    // eslint-disable-next-line
  }, [displaySnackBar, loanClient, navigate, refreshCount]);

  useEffect(() => {
    processApplication();
  }, [processApplication]);

  useEffect(() => {
    if (!processing && waitApplicationResponse && loanId) {
      handleDecision();
    }
  }, [processing, waitApplicationResponse, loanId, handleDecision]);

  return (
    /* eslint-disable  @typescript-eslint/no-non-null-assertion */
    <>
      <Stack flexGrow={1} alignItems="center" padding={'4rem 2rem 4rem 2rem'}>
        <Typography variant="h2" textAlign={'center'}>
          We are processing your application
        </Typography>
        <Loading />
        <Typography variant="body1" marginTop={2}>
          This may take up to <b>five minutes</b>.
        </Typography>
        <Typography variant="body1" marginTop={2}>
          Please be patient and do not close your browser.
        </Typography>
      </Stack>

      <AdBlockPopup handleDialogClose={handleAdBlockPopupClose} open={openAdBlockPopup} />
    </>
  );
};

export default Processing;
