import { Avatar, Box, Stack, Typography } from "@mui/material";
import CloseIcon from '../../assets/img/Close.png';
import { theme } from "../../theme/Theme";

interface HowToGetMoreModalProps {
  showModal: boolean;
  onClose: () => void;
}

const HowToGetMoreModal: React.FunctionComponent<HowToGetMoreModalProps> = (props: HowToGetMoreModalProps) => {
  const { showModal, onClose } = props;

  return (
  <Box
      display={showModal ? 'block' : 'none'}
      position={'absolute'}
      sx={{
        top: { xs: '7rem', md: '4rem'},
        left: { xs: 0, md: '16rem' },
        right: { xs: 0, md: 0 },
        marginLeft: { xs: 'unset', md: 'auto' },
        marginRight: { xs: 'unset', md: 'auto' },
        width: { xs: 'calc(100vw - 2.3rem)', sm: '35.7rem' },
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        borderRadius: '0.8rem',
        transition: 'all 0.25s ease-in-out',
        zIndex: '1',
      }}
    >
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexGrow={1}
        sx={{
          padding: '0.4rem 1.3rem',
          backgroundColor: theme.palette.secondary.main,
          borderTopLeftRadius: '0.8rem',
          borderTopRightRadius: '0.8rem',
        }}
      >
        <Typography
          fontSize={'1.8rem'}
          fontWeight={500}
          lineHeight={'4rem'}
          color={theme.palette.common.white}
          fontStyle={'normal'}
        >
          Unlock new loan terms
        </Typography>
        <Avatar
          src={CloseIcon}
          alt={'Close Icon'}
          sx={{ width: '2.5rem', height: '3.2rem', cursor: 'pointer' }}
          onClick={() => onClose()}
        />
      </Stack>
      <Stack
        flexDirection={'row'}
        sx={{ backgroundColor: theme.palette.common.white,

          borderBottomLeftRadius: '0.8rem',
          borderBottomRightRadius: '0.8rem',
         }}
        >
        <Typography
          fontSize={'1.4rem'}
          lineHeight={'1.5rem'}
          fontWeight={400}
          padding={'1.6rem'}>
          Repaying on time means you can apply for greater loan terms, amounts, and even access new borrowing options.&nbsp;
          <a href="/trust-rating" style={{textDecoration:'none', color:"#008FD5", whiteSpace:"nowrap"}}>Find out more</a>
        </Typography>
      </Stack>
    </Box>
  );
};

export default HowToGetMoreModal;
